import {
    createStudentHousehold,
    updateStudentHousehold,
    deleteStudentHousehold,
    createStudentHouseholdArray
  } from '@/services/api/student-household/student-household'
  
  export default {
    namespaced: true,
    state: {
      allStudentHouseholds: [],
      studentHouseholds: [],
      allStudentHouseholdsLoading: false,
      studentHouseholdsLoading: false,
    },
    mutations: {},
    actions: {
      async createStudentHousehold({ commit }, payload) {
        return createStudentHousehold(payload)
          .then((studentHousehold) => {
            commit(
              'notification/NEW',
              {
                type: 'success',
                message: 'Added a Household',
                description: 'You have successfully added a new Household',
              },
              { root: true },
            )
            return studentHousehold
          })
          .catch((error) => {
            const errorMessage = error.response.data.message
            commit(
              'notification/NEW',
              {
                type: 'warning',
                message: 'Create Error',
                description: errorMessage.join('\n'),
              },
              { root: true },
            )
          })
      },
      async createStudentHouseholdArray({ commit }, payload) {
        return createStudentHouseholdArray(payload)
        .then((studentHouseholds) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a Households',
              description: 'You have successfully added a new Households',
            },
            { root: true },
          )
          return studentHouseholds
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
      },
      async updateStudentHousehold({ commit }, payload) {
        const { id, data } = payload
        return updateStudentHousehold(id, data)
          .then((studentHousehold) => {
            commit(
              'notification/NEW',
              {
                type: 'success',
                message: 'Household updated',
                description: 'You have successfully updated the Household',
              },
              { root: true },
            )
            return studentHousehold
          })
          .catch((error) => {
            const errorMessage = error.response.data.message
            commit(
              'notification/NEW',
              {
                type: 'warning',
                message: 'Update Error',
                description: errorMessage.join('\n'),
              },
              { root: true },
            )
          })
      },
      async deleteStudentHousehold({ commit }, payload) {
        return deleteStudentHousehold(payload)
          .then((studentHousehold) => {
            commit(
              'notification/NEW',
              {
                type: 'success',
                message: 'Household deleted',
                description: 'You have successfully deleted a Household',
              },
              { root: true },
            )
            return studentHousehold
          })
          .catch((error) => {
            console.error(error)
            commit(
              'notification/NEW',
              {
                type: 'error',
                message: 'Delete Error',
                description: 'Faild to delete a Household',
              },
              { root: true },
            )
          })
      },
    },
    getters: {},
  }
  