import {
  listAllDistricts,
  createDistrict,
  getDistrictById,
  updateDistrict,
  deleteDistrict,
  updateDistrictRule,
} from '@/services/api/district/district'

export default {
  namespaced: true,
  state: {
    allDistricts: [],
    districts: [],
    allDistrictsLoading: false,
    districtsLoading: false,
    district: null,
  },
  mutations: {
    SET_ALL_DISTRICTS(state, districts) {
      state.allDistricts = districts
    },
    SET_ALL_DISTRICTS_LOADING(state, loading) {
      state.allDistrictsLoading = loading
    },
    SET_DISTRICTS(state, districts) {
      state.districts = districts
    },
    SET_DISTRICT(state, district) {
      state.district = district
    },
    SET_LOADING(state, loading) {
      state.districtsLoading = loading
    },
  },
  actions: {
    async getAllDistricts({ commit }) {
      commit('SET_ALL_DISTRICTS_LOADING', true)
      return listAllDistricts()
        .then((response) => {
          commit('SET_ALL_DISTRICTS', response.data)
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get distrcts data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_ALL_DISTRICTS_LOADING', false))
    },
    async getDistricts({ commit }, payload) {
      commit('SET_LOADING', true)
      return listAllDistricts()
        .then((districts) => {
          commit('SET_DISTRICTS', districts.data)
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Get Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async getDistrictById({ commit }, id) {
      commit('SET_LOADING', true)
      return getDistrictById(id)
        .then((response) => {
          commit('SET_DISTRICT', response.data)
          return response.data
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Get Error',
              description: 'Faild to get a district',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async createDistrict({ commit }, payload) {
      return createDistrict(payload)
        .then((district) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a district',
              description: 'You have successfully added a new district',
            },
            { root: true },
          )
          return district
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async updateDistrict({ commit }, payload) {
      const { id, data } = payload
      return updateDistrict(id, data)
        .then((district) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'District updated',
              description: 'You have successfully updated a district',
            },
            { root: true },
          )
          return district
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: 'Faild to update a district',
            },
            { root: true },
          )
        })
    },

    async updateDistrictRule({ commit }, payload) {
      const { id, data } = payload
      return updateDistrictRule(id, data)
        .then((district) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'District rule updated',
              description: 'You have successfully updated a district',
            },
            { root: true },
          )
          return district
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: 'Faild to update a district',
            },
            { root: true },
          )
        })
    },

    async deleteDistrict({ commit }, payload) {
      return deleteDistrict(payload)
        .then((district) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'District deleted',
              description: 'You have successfully deleted a district',
            },
            { root: true },
          )
          return district
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Delete Error',
              description: 'Faild to delete a district',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
