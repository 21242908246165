import {
  createRoutingVehicle,
  deleteRoutingVehicle,
  updateRoutingVehicle,
} from '@/services/api/routing-vehicle/routing-vehicle'

export default {
  namespaced: true,
  state: {
    routingVehiclesLoading: false,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.routingVehiclesLoading = loading
    },
  },
  actions: {
    async createRoutingVehicle({ commit }, payload) {
      return createRoutingVehicle(payload)
        .then((routingVehicle) => {
          if (!routingVehicle) return false
          return routingVehicle
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async updateRoutingVehicle({ commit }, payload) {
      return updateRoutingVehicle(payload)
        .then((routingVehicle) => {
          return routingVehicle
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async deleteRoutingVehicle({ commit }, payload) {
      return deleteRoutingVehicle(payload)
        .then((routingVehicle) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'RoutingVehicle deleted',
              description:
                'You have successfully deleted a vehicle in this routing',
            },
            { root: true },
          )
          return routingVehicle
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a routingVehicle',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
