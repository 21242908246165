// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserRole = {
  "ID": "ID",
  "PRIMARYADMIN": "PRIMARYADMIN",
  "ADMIN": "ADMIN",
  "MEMBER": "MEMBER"
};

const RoutingStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DRAFT": "DRAFT"
};

const TimeFormat = {
  "AM": "AM",
  "PM": "PM"
};

const { Company, Stop, Employee, User, VehicleOperator, Student, Guardian, Device, Vehicle, Depot, Telematics, District, VehicleDetails, Country, RoutingColor, Routing, Contract, Building, Document, History, DoctorsDetails, Performance, Requirement, EmergencyContact, OnBoard, PowerTrain, RequiredEndorsements, Partner, StopDistance, MaxRouteLength, Training, Coordinates, CoordinatesWithId } = initSchema(schema);

export {
  Company,
  Stop,
  Employee,
  User,
  VehicleOperator,
  Student,
  Guardian,
  Device,
  Vehicle,
  Depot,
  Telematics,
  District,
  VehicleDetails,
  Country,
  RoutingColor,
  Routing,
  Contract,
  Building,
  UserRole,
  RoutingStatus,
  TimeFormat,
  Document,
  History,
  DoctorsDetails,
  Performance,
  Requirement,
  EmergencyContact,
  OnBoard,
  PowerTrain,
  RequiredEndorsements,
  Partner,
  StopDistance,
  MaxRouteLength,
  Training,
  Coordinates,
  CoordinatesWithId
};