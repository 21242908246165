import {
  listAllCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  uploadLogo,
} from '@/services/api/company/company'

export default {
  namespaced: true,
  state: {
    allCompanies: [],
    companies: [],
    allCompaniesLoading: false,
    companiesLoading: false,
  },
  mutations: {
    SET_ALL_COMPANIES(state, companies) {
      state.allCompanies = companies
    },
    SET_ALL_COMPANIES_LOADING(state, loading) {
      state.allCompaniesLoading = loading
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies
    },
    SET_LOADING(state, loading) {
      state.companiesLoading = loading
    },
  },
  actions: {
    async getCompanies({ commit }) {
      commit('SET_LOADING', true)
      return listAllCompanies()
        .then((companies) => {
          commit('SET_COMPANIES', companies.data)
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Get Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async createCompany({ commit }, payload) {
      return createCompany(payload)
        .then((company) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a company',
              description: 'You have successfully added a new company',
            },
            { root: true },
          )
          return company
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async uploadLogo({ commit }, payload) {
      const { id, data } = payload
      return uploadLogo(id, data)
        .then((company) => {
          if(company){
            commit(
              'notification/NEW',
            {
              type: 'success',
              message: 'Uploaded logo image',
              description: 'You have successfully upload the logo image',
            },
            { root: true },
            )
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async updateCompany({ commit, dispatch }, payload) {
      const { id, data } = payload
      return updateCompany(id, data)
        .then((company) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Company updated',
              description: 'You have successfully updated a company',
            },
            { root: true },
          )
          dispatch('getCompanies');
          return company
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },

    async deleteCompany({ commit }, payload) {
      return deleteCompany(payload)
        .then((company) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Company deleted',
              description: 'You have successfully deleted a company',
            },
            { root: true },
          )
          return company
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Delete Error',
              description: 'Faild to delete a company',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
