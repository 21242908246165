import Vue from 'vue'
import apiClient from '@/services/axios'

export async function createDevice(payload) {
  return apiClient.post('/devices', payload)
}

export async function getDevices(filter) {
  return apiClient.post('/devices/searchDevices', filter)
}

export async function getDeviceByID(id) {
  try {
    const response = await apiClient.get(`/devices/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'Device data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `Device with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching device by ID',
      error,
    }
  }
}

export async function listAllDevices() {
  return apiClient.get('/devices')
}

export async function updateDevice(id, deviceData) {
  try {
    const response = await apiClient.patch(`/devices/${id}`, deviceData)
    return {
      status: response.status,
      message: 'Device updated successfully',
      data: response.data,
    }
  } catch (error) {
    console.error('Error updating device:', error)
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error updating device',
      error: error.response ? error.response.data : error.message,
    }
  }
}

export async function deleteDevice(id) {
  return apiClient.delete(`/devices/${id}`)
}

export async function deleteDevices(ids) {
  return apiClient.delete('/devices', { data: { ids } })
}

export async function uploadExistingDeviceData(data) {
  const dResponse = await listAllDevices()
  const allDevices = dResponse.data
  for (let i = 0; i < data.length; i++) {
    const element = data[i]
    if (!element.deviceType) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device Type is empty`,
      })
      return
    }
    if (!element.deviceMake) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device Make is empty`,
      })
      return
    }
    if (!element.deviceModel) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device Model is empty`,
      })
      return
    }
    if (!element.deviceSerialNumber) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device Serial Number is empty`,
      })
      return
    }
    if (!element.deviceIMEINumber) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device IMEI Number is empty`,
      })
      return
    }
    if (!['IOT'].includes(element.deviceType)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device Type is not correct`,
      })
      return
    }
    if (!['Teltonika', 'Morey'].includes(element.deviceMake)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device Make is not correct`,
      })
      return
    }
    let modelArray = []
    if (element.deviceMake === 'Teltonika') {
      modelArray = ['FMM130', 'FMM13A', 'FMM650']
    } else {
      modelArray = ['MCX301']
    }
    if (!modelArray.includes(element.deviceModel)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device Model is not correct`,
      })
      return
    }
    const device = allDevices.filter(
      (device) =>
        device.imeiNumber === element.deviceIMEINumber.toString().trim(),
    )
    if (device.length > 0) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Device IMEI Number is already exist`,
      })
      return
    }
  }

  try {
    let failed = []
    let success = []
    let exists = []
    let response
    for (let v = 0; v < data.length; v++) {
      const element = data[v]
      if (
        element.vehicleIdentifier &&
        element.licensePlate &&
        element.vinNumber
      ) {
        const vData = {
          vehicleId: element.vehicleIdentifier,
          vinNumber: element.vinNumber,
          licensePlate: element.licensePlate,
          installDate: element.deviceInstallDate,
          startDate: element.deviceStartDate,
          type: element.deviceType,
          make: element.deviceMake,
          model: element.deviceModel,
          serialNumber: element.deviceSerialNumber,
          imeiNumber: element.deviceIMEINumber,
          notes: element.deviceNotes,
        }
        if (element.vehicleIdentifier) {
          const response = await createDevice(vData)
          if (response) {
            success.push(element)
          }
          if (!response) {
            failed.push(element)
            console.error(uploadVData.message)
          }
        }

        if (v === data.length - 1) {
          return (response = {
            status: 200,
            message: 'Device data uploaded successfully',
            failed,
            success,
            exists,
          })
        }
      }
    }
  } catch (error) {
    return { status: 500, message: ':::: UploadExistingDeviceData :::', error }
  }
}
