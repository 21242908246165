import {
  getAllBuildings,
  getBuildingById,
  createBuilding,
  updateBuilding,
  deleteBuilding,
  getBuildings
} from '@/services/api/building/building'

export default {
  namespaced: true,
  state: {
    allBuildings: [],
    buildings: [],
    buildingsCount: 0,
    loading: false,
    building: null,
  },
  mutations: {
    SET_ALL_BUILDINGS(state, buildings) {
      state.allBuildings = buildings
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_BUILDINGS(state, buildings) {
      state.buildings = buildings
    },
    SET_BUILDING(state, building) {
      state.building = building
    },
    SET_BUILDINGS_COUNT(state, buildingsCount) {
      state.buildingsCount = buildingsCount
    },
  },
  actions: {
    async getAllBuildings({ commit }) {
      commit('SET_LOADING', true)
      return getAllBuildings()
        .then((response) => {
          commit('SET_ALL_BUILDINGS', response.data)
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get building data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },

    async getBuildings({ commit }, payload) {
      commit('SET_LOADING', true)
      return getBuildings(payload)
        .then((res) => {
          commit('SET_BUILDINGS', res.data.buildings)
          return res
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get buildings data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * get a building by id
     */
    async getBuildingById({ commit }, payload) {
      commit('SET_LOADING', true)
      return getBuildingById(payload.id)
        .then((response) => {
          commit('SET_BUILDING', response.data)
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async createBuilding({ commit }, payload) {
      return createBuilding(payload)
        .then((building) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a building',
              description: 'You have successfully added a new Building',
            },
            { root: true },
          )
          return building
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Create Error',
              description: 'Faild to create a building',
            },
            { root: true },
          )
        })
    },
    async updateBuilding({ commit }, payload) {
      return updateBuilding(payload)
        .then((response) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Building updated',
              description: 'You have successfully updated a building',
            },
            { root: true },
          )
          return response.data
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Update Error',
              description: 'Faild to update a building',
            },
            { root: true },
          )
        })
    },
    async deleteBuilding({ commit }, payload) {
      return deleteBuilding(payload)
        .then((building) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Building deleted',
              description: 'You have successfully deleted a building',
            },
            { root: true },
          )
          return building
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a building',
            },
            { root: true },
          )
        })
    },
  },
  getters: {
    totalBuildingsCount: (state) => {
      return state.allBuildings.length
    },
  },
}
