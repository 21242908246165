import {
  createVehicle,
  getVehicles,
  deleteVehicle,
  updateVehicle,
  listAllVehicle,
  deleteVehicles,
} from '@/services/api/vehicle/vehicle'

export default {
  namespaced: true,
  state: {
    allVehicles: [],
    vehicles: [],
    allVehiclesLoading: false,
    vehiclesLoading: false,
  },
  mutations: {
    SET_ALL_VEHICLES(state, vehicles) {
      state.allVehicles = vehicles
    },
    SET_ALL_VEHICLES_LOADING(state, loading) {
      state.allVehiclesLoading = loading
    },
    SET_VEHICLES(state, vehicles) {
      state.vehicles = vehicles
    },
    SET_LOADING(state, loading) {
      state.vehiclesLoading = loading
    },
  },
  actions: {
    async getVehicles({ commit }, payload) {
      commit('SET_LOADING', true)
      return getVehicles(payload)
        .then((res) => {
          commit('SET_VEHICLES', res.data.vehicles)
          return res
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get vehicles data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async createVehicle({ commit }, payload) {
      return createVehicle(payload)
      .then((vehicle) => {
        if (!vehicle) return false
        commit(
          'notification/NEW',
          {
            type: 'success',
            message: 'Added a vehicle',
            description: 'You have successfully added a new vehicle',
          },
          { root: true },
        )
        return vehicle
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        commit(
          'notification/NEW',
          {
            type: 'warning',
            message: 'Create Error',
            description: errorMessage.join('\n'),
          },
          { root: true },
        )
      })
    },
    async updateVehicle({ commit }, payload) {
      const { id, data } = payload
      return updateVehicle(id, data)
        .then((vehicle) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Vehicle updated',
              description: 'You have successfully updated the vehicle',
            },
            { root: true },
          )
          return vehicle
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async deleteVehicle({ commit }, payload) {
      return deleteVehicle(payload)
        .then((vehicle) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Vehicle deleted',
              description: 'You have successfully deleted a vehicle',
            },
            { root: true },
          )
          return vehicle
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a vehicle',
            },
            { root: true },
          )
        })
    },
    async deleteVehicles({ commit }, payload) {
      return deleteVehicles(payload)
        .then((vehicle) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Vehicles deleted',
              description: 'You have successfully deleted a vehicles',
            },
            { root: true },
          )
          return vehicle
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a vehicles',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
