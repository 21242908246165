import { render, staticRenderFns } from "./item.vue?vue&type=template&id=6a1adc2b&scoped=true"
import script from "./item.vue?vue&type=script&lang=js"
export * from "./item.vue?vue&type=script&lang=js"
import style0 from "./item.vue?vue&type=style&index=0&id=6a1adc2b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a1adc2b",
  null
  
)

export default component.exports