<template>
  <svg
    width="27"
    height="25"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3122 6.53116C17.3122 8.82514 15.4485 10.6873 13.1464 10.6873C10.8442 10.6873 8.98047 8.82514 8.98047 6.53116C8.98047 4.23717 10.8442 2.375 13.1464 2.375C15.4485 2.375 17.3122 4.23717 17.3122 6.53116Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M15.9832 3.12291C16.3139 2.55116 16.8073 2.0899 17.401 1.79767C17.9948 1.50544 18.6622 1.39545 19.3187 1.48171C19.9753 1.56797 20.5912 1.84658 21.0888 2.28208C21.5864 2.71757 21.9432 3.29035 22.1144 3.92789C22.2855 4.56541 22.2634 5.23926 22.0508 5.86427C21.8383 6.4893 21.4446 7.03762 20.9196 7.43973C20.3945 7.84186 19.7615 8.07967 19.1007 8.1229C18.4399 8.16612 17.7812 8.0128 17.2079 7.68246"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M10.274 3.13831C9.94932 2.55637 9.46758 2.09126 8.89307 1.79806C8.31889 1.50503 7.67572 1.3956 7.04399 1.48167C6.41212 1.56776 5.81585 1.84619 5.3315 2.28577C4.84686 2.72563 4.49599 3.30737 4.3272 3.95928C4.15837 4.61133 4.18026 5.30086 4.38977 5.93956C4.59921 6.5781 4.98564 7.13406 5.4962 7.5395C6.00645 7.94468 6.61829 8.182 7.25399 8.22512C7.88961 8.26823 8.52493 8.11558 9.08016 7.78384"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M13.1453 13.8906C20.3812 13.8906 21.8075 20.2159 22.0887 22.7095C22.1506 23.2583 21.71 23.7029 21.1577 23.7029H5.13281C4.58053 23.7029 4.13996 23.2583 4.20184 22.7095C4.48299 20.2159 5.90932 13.8906 13.1453 13.8906Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M25.2605 19.3472L25.9953 19.1972V19.1972L25.2605 19.3472ZM14.9258 14.038L14.372 13.5322L13.4401 14.5527L14.8035 14.778L14.9258 14.038ZM21.6388 20.4347L20.9186 20.6439L21.0756 21.1847H21.6388V20.4347ZM18.8836 13.0078C20.7654 13.0078 22.0394 14.0227 22.9342 15.3808C23.8416 16.758 24.306 18.4218 24.5256 19.4973L25.9953 19.1972C25.7606 18.0476 25.2483 16.1667 24.1868 14.5555C23.1126 12.9251 21.4253 11.5078 18.8836 11.5078V13.0078ZM15.4796 14.5438C16.3106 13.6338 17.401 13.0078 18.8836 13.0078V11.5078C16.9095 11.5078 15.4361 12.367 14.372 13.5322L15.4796 14.5438ZM14.8035 14.778C18.6131 15.4075 20.2227 18.2473 20.9186 20.6439L22.3591 20.2256C21.5888 17.5728 19.6805 14.0635 15.0481 13.298L14.8035 14.778ZM24.3328 19.6847H21.6388V21.1847H24.3328V19.6847ZM24.5256 19.4973C24.5361 19.5483 24.5236 19.5856 24.4961 19.6173C24.4655 19.6525 24.4108 19.6847 24.3328 19.6847V21.1847C25.3524 21.1847 26.2177 20.2865 25.9953 19.1972L24.5256 19.4973Z"
      fill="currentColor"
    />
    <path
      d="M11.3694 14.0383L11.4918 14.7782L12.8549 14.5527L11.9233 13.5325L11.3694 14.0383ZM1.03449 19.3472L1.76933 19.4973L1.03449 19.3472ZM4.65799 20.4347V21.1847H5.22121L5.37825 20.6439L4.65799 20.4347ZM7.41142 13.0078C8.89408 13.0078 9.9846 13.6339 10.8156 14.544L11.9233 13.5325C10.8591 12.3671 9.38571 11.5078 7.41142 11.5078V13.0078ZM1.76933 19.4973C1.98893 18.4218 2.45334 16.758 3.3608 15.3808C4.2556 14.0227 5.52964 13.0078 7.41142 13.0078V11.5078C4.86971 11.5078 3.18244 12.9251 2.10823 14.5555C1.04668 16.1667 0.53438 18.0476 0.299651 19.1972L1.76933 19.4973ZM1.96212 19.6847C1.88417 19.6847 1.82941 19.6525 1.7989 19.6173C1.7714 19.5856 1.7589 19.5483 1.76933 19.4973L0.299651 19.1972C0.0772248 20.2865 0.942569 21.1847 1.96212 21.1847V19.6847ZM4.65799 19.6847H1.96212V21.1847H4.65799V19.6847ZM5.37825 20.6439C6.074 18.2476 7.68336 15.4082 11.4918 14.7782L11.247 13.2983C6.61586 14.0644 4.70788 17.5731 3.93774 20.2256L5.37825 20.6439Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'StudentsIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
