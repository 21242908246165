import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import map from './map'
import contracts from './contracts'
import districts from './districts'
import buildings from './buildings'
import routings from './routings'
import notification from './notification'
import telematics from './telematics'
import vehicles from './vehicles'
import devices from './devices'
import depots from './depots'
import companies from './companies'
import organizations from './organizations'
import students from './students'
import employees from './employees'
import emergencyContacts from './emergency-contacts'
import stops from './stops'
import routingGroups from './routing-groups'
import routingColors from './routing-colors'
import routingPackages from './routing-packages'
import specializedRoutings from './specialized-routings'
import studentHouseholds from './student-households'
import routingVehicles from './routing-vehicles'
import analytics from './analytics'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
    map,
    contracts,
    vehicles,
    devices,
    districts,
    depots,
    companies,
    organizations,
    buildings,
    routings,
    specializedRoutings,
    notification,
    telematics,
    students,
    studentHouseholds,
    employees,
    emergencyContacts,
    stops,
    routingGroups,
    routingColors,
    routingPackages,
    routingVehicles,
    analytics
  },
})
