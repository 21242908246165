import {
  createRouting,
  getRoutings,
  updateRouting,
  getRoutingById,
  deleteRouting,
} from '@/services/api/routing/routing'
import { getStudentsByBuilding } from '@/services/api/student/student'
import { getDistrictById } from '@/services/api/district/district'
import {
  updateStop,
  getStops,
  assignStudentToManualStop,
  unassignStudentFromRouting,
  unassignStudentsFromRouting,
} from '@/services/api/stop/stop'
import {
  getStudentById,
  getStudentsByPaginate,
} from '@/services/api/student/student'

export default {
  namespaced: true,
  state: {
    currentGeometryData: [],
    routingType: 'fixed',
    allRoutesLoading: false,
    routingGroupName: '',
    routings: [],
    routingSteps: {
      contractId: null,
      districtId: null,
      buildingIds: [],
      educationType: 'Regular',
      company: null,
      depots: [],
      shift: 'AM',
      grades: [],
      arriveAtBuilding: 0,
      days: [],
      startDate: null,
      endDate: null,
      studentList: [],
      additionalStudents: [],
    },
    module: {
      routeName: '',
      stops: [],
      buildings: [],
      depotOptions: [],
      buildingOptions: [],
      currentDistrict: null,
      currentDepot: null,
      routeLengthDeadHead: 0,
      routeLengthCustomer: 0,
      studentsCount: 0,
    },
    currentRouting: null,
    routingGroup: null,
    students: [], // students filtered by building ids for routing
  },
  mutations: {
    SET_CURRENT_GEOMETRY_DATA(state, payload) {
      state.currentGeometryData = payload
    },
    SET_ALL_ROUTES_LOADING(state, loading) {
      state.allRoutesLoading = loading
    },
    CHANGE_ROUTING_STEPS(state, payload) {
      state.routingSteps[payload.name] = payload.value
    },
    SET_ROUTING_STEPS(state, payload) {
      state.routingSteps = payload
    },
    CHANGE_ROUTING_TYPE(state, payload) {
      state.routingType = payload
    },
    RESET_ROUTING_STEPS(state) {
      state.routingSteps = {
        contractId: null,
        districtId: null,
        buildingIds: [],
        educationType: 'Regular',
        company: null,
        depots: [],
        shift: 'AM',
        grades: [],
        arriveAtBuilding: 0,
        days: [],
        startDate: null,
        endDate: null,
        studentList: [],
        additionalStudents: [],
      }
    },
    SET_MODULE(state, payload) {
      state.module[payload.name] = payload.value
    },
    SET_ROUTE_NAME(state, payload) {
      state.module.routeName = payload
    },
    SET_STUDENTS(state, payload) {
      state.students = payload
    },
    SET_ROUTING_GROUP_NAME(state, payload) {
      state.routingGroupName = payload
    },
    SET_CURRENT_DISTRICT(state, payload) {
      state.module.currentDistrict = payload
    },
    SET_CURRENT_ROUTING(state, payload) {
      state.currentRouting = payload
    },
    SET_STOPS(state, payload) {
      state.module.stops = payload
    },
    SET_STOP(state, payload) {
      state.module.stops[payload.index] = payload.stop
    },
    SET_CURRENT_DEPOT(state, payload) {
      state.module.currentDepot = payload
    },
    SET_ROUTING_GROUP(state, payload) {
      state.routingGroup = payload
    },
    SET_ROUTINGS(state, payload) {
      state.routings = payload
    },
    SET_MODULE_BUILDINGS(state, payload) {
      state.module.buildings = payload
    },
  },
  actions: {
    setCurrentGeometryData({ commit }, payload) {
      commit('SET_CURRENT_GEOMETRY_DATA', payload)
    },
    async getRoutings({ commit }, payload) {
      return getRoutings(payload)
        .then((response) => {
          // Sort the stops by index within each routing
          const sortedRoutings = response.data.data.map((routing) => {
            return {
              ...routing,
              stops: [...routing.stops].sort((a, b) => a.index - b.index), // Sort stops by index
            }
          })

          commit('SET_ROUTINGS', sortedRoutings)
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getRoutingById({ commit }, payload) {
      return getRoutingById(payload)
        .then((response) => {
          const routing = response.data

          // Sort stops by index
          routing.stops.sort((a, b) => a.index - b.index)

          commit('SET_CURRENT_ROUTING', routing)
          return routing
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getStudentsByBuilding({ commit, state }, payload) {
      // TODO: need to be updated
      commit('SET_ALL_ROUTES_LOADING', true)
      return getStudentsByBuilding(payload)
        .then((response) => {
          commit('SET_STUDENTS', [
            ...response.data.data,
            ...state.routingSteps.additionalStudents,
          ])
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => commit('SET_ALL_ROUTES_LOADING', false))
    },
    async createRouting({ commit }, payload) {
      return createRouting(payload)
        .then((response) => {
          commit('SET_CURRENT_ROUTING', response.data)
          return response.data
        })
        .catch((error) => {
          if (error.response) {
            commit(
              'notification/NEW',
              {
                type: 'error',
                message: 'Error',
                description: error.response.data.message,
              },
              { root: true },
            )
          } else {
            console.error(error)
          }
        })
    },
    async updateRouting({ commit }, payload) {
      return updateRouting(payload)
        .then((response) => {
          commit('SET_CURRENT_ROUTING', response.data)
          return response.data
        })
        .catch((error) => {
          if (error.response) {
            commit(
              'notification/NEW',
              {
                type: 'error',
                message: 'Error',
                description: error.response.data.message,
              },
              { root: true },
            )
          } else {
            console.error(error)
          }
        })
    },
    async getCurrentDistrict({ commit }, payload) {
      return getDistrictById(payload)
        .then((response) => {
          commit('SET_CURRENT_DISTRICT', response.data)
        })
        .catch((error) => {
          throw error
        })
    },
    async updateStopName({ commit, state }, payload) {
      return updateStop(payload)
        .then((response) => {
          const index = state.module.stops.findIndex(
            (stop) => Number(stop.id) === Number(payload.id),
          )
          const temp = [...state.module.stops]
          temp[index] = response.data
          commit('SET_STOPS', temp)
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async deleteRouting({ commit }, payload) {
      return deleteRouting(payload)
        .then(() => {})
        .catch((error) => {
          console.error(error)
          throw error
        })
    },
    async assignStudentToManualStop({ commit, rootState }, payload) {
      try {
        const response = await assignStudentToManualStop(payload)

        // Refetch stops
        const res = await getStops({
          limit: -1,
          'filter.routing.id': rootState.routings.currentRouting.id,
        })
        // order by index
        res.data.data.sort((a, b) => a.index - b.index)
        commit('SET_STOPS', res.data.data)

        return response.data
      } catch (error) {
        console.error(error)
        throw error // Re-throw the error to handle it in the calling code if needed
      }
    },
    async unassignStudentFromRouting({ commit, state }, payload) {
      try {
        const response = await unassignStudentFromRouting(payload)

        // Refetch stops
        const res = await getStops({
          limit: -1,
          'filter.routing.id': payload.routingId,
        })

        // order by index
        res.data.data.sort((a, b) => a.index - b.index)
        commit('SET_STOPS', res.data.data)

        // get student
        const { data: student } = await getStudentById(payload.studentId)

        // update students
        const studentIndex = state.students.findIndex(
          (s) => s.id === student.id,
        )

        const temp = [...state.students]
        temp[studentIndex] = student
        commit('SET_STUDENTS', temp)

        return response.data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async unassignStudentsFromRouting({ commit, state }, payload) {
      try {
        const response = await unassignStudentsFromRouting(payload)

        // Refetch stops
        const res = await getStops({
          limit: -1,
          'filter.routing.id': payload.routingId,
        })

        // order by index
        res.data.data.sort((a, b) => a.index - b.index)
        commit('SET_STOPS', res.data.data)

        // get students
        const {
          data: { data: students },
        } = await getStudentsByPaginate({
          limit: -1,
          'filter.id': `$in${payload.studentIds.join(',')}`,
        })

        // update students: replace
        const temp = [...state.students]
        students.forEach((student) => {
          const index = temp.findIndex((s) => s.id === student.id)
          temp[index] = student
        })
        commit('SET_STUDENTS', temp)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    changeRoutingType(state, payload) {
      state.commit('CHANGE_ROUTING_TYPE', payload)
    },
    changeRoutingSteps(state, payload) {
      state.commit('CHANGE_ROUTING_STEPS', payload)
    },
    setModule(state, payload) {
      state.commit('SET_MODULE', payload)
    },
    resetModule(state) {
      state.commit('SET_MODULE', { name: 'stops', value: [] })
      state.commit('SET_MODULE', { name: 'routeName', value: '' })
      state.commit('SET_MODULE', { name: 'routeLengthDeadHead', value: 0 })
      state.commit('SET_MODULE', { name: 'routeLengthCustomer', value: 0 })
      state.commit('SET_MODULE', { name: 'studentsCount', value: 0 })
    },
    updateRoutingGroupName(state, payload) {
      state.commit('SET_ROUTING_GROUP_NAME', payload)
    },
    setRouteName(state, payload) {
      state.commit('SET_ROUTE_NAME', payload)
    },
    setStops(state, payload) {
      state.commit('SET_STOPS', payload)
    },
    setCurrentDepot(state, payload) {
      state.commit('SET_CURRENT_DEPOT', payload)
    },
    setCurrentDistrict(state, payload) {
      state.commit('SET_CURRENT_DISTRICT', payload)
    },
    setRoutingSteps(state, payload) {
      state.commit('SET_ROUTING_STEPS', payload)
    },
    resetRoutingSteps(state) {
      state.commit('RESET_ROUTING_STEPS')
    },
    setCurrentRouting(state, payload) {
      state.commit('SET_CURRENT_ROUTING', payload)
    },
    setRoutingGroup(state, payload) {
      state.commit('SET_ROUTING_GROUP', payload)
    },
    setModuleBuildings(state, payload) {
      state.commit('SET_MODULE_BUILDINGS', payload)
    },
  },
  getters: {},
}
