import {
  listAllOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  uploadLogo,
} from '@/services/api/organization/organization'

export default {
  namespaced: true,
  state: {
    allOrganizations: [],
    organizations: [],
    allOrganizationsLoading: false,
    organizationsLoading: false,
  },
  mutations: {
    SET_ALL_ORGS(state, organizations) {
      state.allOrganizations = organizations
    },
    SET_ALL_ORGS_LOADING(state, loading) {
      state.allOrganizationsLoading = loading
    },
    SET_ORGS(state, organizations) {
      state.organizations = organizations
    },
    SET_LOADING(state, loading) {
      state.organizationsLoading = loading
    },
  },
  actions: {
    async getOrganizations({ commit }) {
      commit('SET_LOADING', true)
      return listAllOrganizations()
        .then((organizations) => {
          commit('SET_ORGS', organizations.data)
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Get Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async createOrganization({ commit }, payload) {
      return createOrganization(payload)
        .then((organization) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a organization',
              description: 'You have successfully added a new organization',
            },
            { root: true },
          )
          return organization
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async uploadLogo({ commit }, payload) {
      const { id, data } = payload
      return uploadLogo(id, data)
        .then((organization) => {
          if(organization){
            commit(
              'notification/NEW',
            {
              type: 'success',
              message: 'Uploaded logo image',
              description: 'You have successfully upload the logo image',
            },
            { root: true },
            )
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async updateOrganization({ commit, dispatch }, payload) {
      const { id, data } = payload
      return updateOrganization(id, data)
        .then((organization) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Organization updated',
              description: 'You have successfully updated a organization',
            },
            { root: true },
          )
          dispatch('getOrganizations');
          return organization
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },

    async deleteOrganization({ commit }, payload) {
      return deleteOrganization(payload)
        .then((organization) => {
          return organization
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Delete Error',
              description: 'Faild to delete a organization',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
