import {
  createRoutingGroup,
  getRoutingGroups,
} from '@/services/api/routing-group/routing-group'

export default {
  namespaced: true,
  state: {
    routingGroups: [],
    loading: false,
  },
  mutations: {
    SET_ROUTING_GROUPS(state, payload) {
      state.routingGroups = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async getAllRoutingGroups({ commit }) {
      commit('SET_LOADING', true)
      return getRoutingGroups({ limit: -1, sortBy: 'routingGroupName:ASC' })
        .then((response) => {
          commit('SET_ROUTING_GROUPS', response.data.data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async getRoutingGroups({ commit }, payload) {
      commit('SET_LOADING', true)
      return getRoutingGroups(payload)
        .then((response) => {
          commit('SET_ROUTING_GROUPS', response.data.data)
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async createRoutingGroup({ commit }, payload) {
      return createRoutingGroup(payload)
        .then((response) => {
          commit('routings/SET_ROUTING_GROUP', response.data, { root: true })
          return response.data
        })
        .catch((error) => {
          if (error.response) {
            commit(
              'notification/NEW',
              {
                type: 'error',
                message: 'Error',
                description: error.response.data.message,
              },
              { root: true },
            )
          } else {
            console.error(error)
          }
        })
    },
  },
  getters: {},
}
