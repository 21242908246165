import apiClient from '@/services/axios'

export async function createEmergencyContact(payload) {
  return await apiClient.post('/emergency-contacts', payload)
}

export async function createEmergencyContactArray(payload) {
  return await apiClient.post('/emergency-contacts/array', payload)
}

export async function uploadEmergencyContact(payload) {
  return await apiClient.post('/emergency-contacts/upload', payload)
}

export async function getEmergencyContactById(id) {
  try {
    const response = await apiClient.get(`/emergency-contacts/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'EmergencyContact data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `EmergencyContact with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching EmergencyContact by ID',
      error,
    }
  }
}

export async function updateEmergencyContact(id, emergencyContactData) {
  return await apiClient.patch(
    `/emergency-contacts/${id}`,
    emergencyContactData,
  )
}

export async function deleteEmergencyContact(id) {
  return await apiClient.delete(`/emergency-contacts/${id}`)
}

export async function uploadEmergencyContactData(data) {
  for (let i = 0; i < data.length; i++) {
    const emergencyContactArray = []
    if (data[i].emergencyContactFirstName1?.toString().trim()) {
      const contact = {
        employeeId: data[i]?.employeeID,
        firstName: data[i]?.emergencyContactFirstName1,
        lastName: data[i]?.emergencyContactLastName1,
        relation: data[i]?.emergencyContactRelation1,
        gender: data[i]?.emergencyContactGender1,
        address: data[i]?.emergencyContactAddress1,
        birthDay: data[i]?.emergencyContactBirthDay1,
        phone: data[i]?.emergencyContactPhone1,
      }
      emergencyContactArray.push(contact)
    }
    if (data[i].emergencyContactFirstName2?.toString().trim()) {
      const contact = {
        employeeId: data[i]?.employeeID,
        firstName: data[i]?.emergencyContactFirstName2,
        lastName: data[i]?.emergencyContactLastName2,
        relation: data[i]?.emergencyContactRelation2,
        gender: data[i]?.emergencyContactGender2,
        address: data[i]?.emergencyContactAddress2,
        birthDay: data[i]?.emergencyContactBirthDay2,
        phone: data[i]?.emergencyContactPhone2,
      }
      emergencyContactArray.push(contact)
    }
    await uploadEmergencyContact(emergencyContactArray)
  }
}