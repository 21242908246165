import { getRoutingColors } from '@/services/api/routing-color/routing-color'

export default {
  namespaced: true,
  state: {
    routingColors: [],
    loading: false,
  },
  mutations: {
    SET_ROUTING_COLORS(state, routingColors) {
      state.routingColors = routingColors
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    async getRoutingColors({ commit }) {
      commit('SET_LOADING', true)
      return getRoutingColors()
        .then((response) => {
          commit('SET_ROUTING_COLORS', response.data)
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => commit('SET_LOADING', false))
    },
  },
  getters: {},
}
