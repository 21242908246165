<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    class="plus-icon"
  >
    <g id="Group 2400">
      <path
        id="Vector 170"
        d="M17.4386 6.86016L17.3158 6.54551C16.435 4.28827 14.6392 2.50979 12.3736 1.65087C10.4177 0.909371 8.25801 0.909371 6.30209 1.65087L6.15134 1.70802C3.98166 2.53056 2.26023 4.23816 1.41675 6.39978C0.642091 8.38505 0.671329 10.5984 1.50108 12.5612C2.29995 14.4511 3.77996 15.9716 5.64753 16.8212L5.87258 16.9236C8.08122 17.9283 10.6096 17.9628 12.8448 17.0186L13.2154 16.862C15.266 15.9958 16.8477 14.2931 17.5609 12.1843C18.1472 10.4504 18.1039 8.56534 17.4386 6.86016Z"
        stroke="currentColor"
      />
      <g id="Group 1766">
        <path
          id="Line 57"
          d="M9.33789 5.5L9.33789 13.5"
          stroke="currentColor"
          stroke-linecap="round"
        />
        <path
          id="Line 58"
          d="M13.3945 9.5L5.28618 9.48828"
          stroke="currentColor"
          stroke-linecap="round"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
  props: ['color'],
  data: function() {
    return {
    }
  },
  mounted() {
  }
}
</script>
