<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 6.70312H19V15.7031C19 16.6459 19 17.1173 18.7071 17.4102C18.4142 17.7031 17.9428 17.7031 17 17.7031H13V6.70312Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M1 6.70312H7V17.7031H3C2.05719 17.7031 1.58579 17.7031 1.29289 17.4102C1 17.1173 1 16.6459 1 15.7031V6.70312Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <rect
      x="7"
      y="6.70312"
      width="6"
      height="11"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M1 3.70312C1 2.76032 1 2.28891 1.29289 1.99602C1.58579 1.70312 2.05719 1.70312 3 1.70312H17C17.9428 1.70312 18.4142 1.70312 18.7071 1.99602C19 2.28891 19 2.76032 19 3.70313V6.70312H1V3.70312Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SchemeticIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
