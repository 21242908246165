<template>
  <a-layout-sider
    class="sidebar-a-layout"
    :width="settings.leftMenuWidth"
    :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView"
    @collapse="onCollapse"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width:
          settings.isMenuCollapsed && !settings.isMobileView
            ? '80px'
            : settings.leftMenuWidth + 'px',
        height:
          settings.isMobileView || settings.isMenuUnfixed
            ? 'calc(100%)'
            : 'calc(100% - 48px)',
      }"
    >
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <img
            v-if="settings.isMenuCollapsed && !settings.isMobileView"
            src="resources/images/routr-brandmark.svg"
            class="mr-2"
            style="width: 40px;"
            alt="Routr"
          />
          <img
            v-else
            src="resources/images/routr_logo.svg"
            class="mr-2"
            style="width: 100%; max-width: 124px"
            alt="Routr"
          />
        </div>
      </div>
      <vue-custom-scrollbar
        :style="{
          height:
            settings.isMobileView || settings.isMenuUnfixed
              ? 'calc(100% - 64px)'
              : 'calc(100vh - 110px)',
          overflowY: 'auto',
          overflowX: 'clip'
        }"
      >
        <a-menu
          forceSubMenuRender
          :inlineCollapsed="
            settings.isMobileView ? false : settings.isMenuCollapsed
          "
          :mode="'inline'"
          :selectedKeys="selectedKeys"
          :openKeys.sync="openKeys"
          @click="handleClick"
          @openChange="handleOpenChange"
          :inlineIndent="15"
          :class="settings.isMenuCollapsed ? $style.navigation2: $style.navigation"
          id="sidebar-item"
        >
          <template v-for="(item, index) in menuData">
            <template v-if="!item.roles || item.roles.includes(user.role)">
              <a-menu-item-group
                id="sidebar-tip"
                :key="index"
                v-if="item.category"
              >
                <template slot="title">{{ item.title }}</template>
              </a-menu-item-group>
              <item
                :disabled="item.disabled"
                v-if="!item.children && !item.category"
                :menu-info="item"
                :styles="$style"
                :collapsed="settings.isMenuCollapsed"
                :key="item.key"
              />
              <!-- <item
                :disabled="item.disabled"
                v-if="!item.children && !item.category && index !== 0"
                :menu-info="item"
                :styles="$style"
                :collapsed="settings.isMenuCollapsed"
                :key="item.key"
              /> -->
              <sub-menu
                id="sidebar-item-submenu"
                v-if="item.children"
                :menu-info="item"
                :styles="$style"
                :key="item.key"
              />
            </template>
          </template>
        </a-menu>
      </vue-custom-scrollbar>
    </div>
  </a-layout-sider>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import find from 'lodash/find'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import { getMenuData, getSettingMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'menu-left',
  components: { vueCustomScrollbar, SubMenu, Item },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    this.setSelectedKeys()
    this.settings.menuType === 'main'
      ? (this.menuData = getMenuData)
      : (this.menuData = getSettingMenuData)
  },
  data() {
    return {
      menuData: getMenuData,
      selectedKeys: [],
      openKeys: [],
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    'settings.menuType'() {
      this.settings.menuType === 'main'
        ? (this.menuData = getMenuData)
        : (this.menuData = getSettingMenuData)
    },
    $route() {
      this.setSelectedKeys()
    },
  },
  methods: {
    onCollapse: function (collapsed, type) {
      const value = !this.settings.isMenuCollapsed
      this.$store.commit('CHANGE_SETTING', {
        setting: 'isMenuCollapsed',
        value,
      })
    },
    handleClick(e) {
      // if (e.key === 'settings') {
      //   this.$store.commit('CHANGE_SETTING', {
      //     setting: 'isSettingsOpen',
      //     value: true,
      //   })
      //   return
      // }
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
      if (e.key === 'gotoDashboard') {
        store.set('app.menu.selectedKeys', ['contracts'])
        this.selectedKeys = ['contracts']
      }
    },
    handleOpenChange(openKeys) {
      store.set('app.menu.openedKeys', openKeys)
      this.openKeys = openKeys
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
      if(pathname === '/preferences/profile') {
        this.selectedKeys= ['profile']
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss">
.ps__rail-y {
  display: none !important;
}

.menu-icon-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#sidebar-tip .ant-menu-item-group-title {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  display: flex;
  align-items: center;
  letter-spacing: 2px !important;
  color: #1ad697 !important;
  margin-top: 28px;
}
#sidebar-item a span {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  //display: flex !important;
  align-items: center !important;
  color: #152f4a !important;
}
#sidebar-item-submenu div span span {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  //display: flex !important;
  align-items: center !important;
  color: #152f4a !important;
}
#sidebar-item .router-link-active span {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  //display: flex !important;
  //align-items: center !important;
  color: #ffffff !important;
}
#sidebar-item a {
  display: flex !important;
  align-items: center !important;
}
#sidebar-item-submenu div {
  display: flex !important;
  align-items: center !important;
}
#sidebar-item li a {
  //display: flex !important;
  //align-items: center !important;
  margin-top: 8px !important;
}
#sidebar-item li a i {
  color: #152f4a !important;
  font-size: 18px !important;
}
#sidebar-item li .router-link-active i {
  color: #ffffff !important;
  font-size: 14px !important;
}
#sidebar-item-submenu {
  &:hover {
    background: #ffffff !important;
  }
  background: #ffffff !important;
}
#sidebar-item-submenu div {
  padding-right: unset !important;
}
#sidebar-item-submenu div i::before {
  color: #152f4a !important;
  font-size: 18px !important;
}
#sidebar-item-submenu div i::after {
  background-image: linear-gradient(to right, #152f4a, #152f4a) !important;
  font-size: 18px !important;
  // width: 8px !important;
}
#sidebar-item-submenu div i::before {
  background-image: linear-gradient(to right, #152f4a, #152f4a) !important;
  font-size: 18px !important;
  // width: 8px !important;
}
#sidebar-item-submenu div i {
  opacity: 1 !important;
  right: 16px !important;
}
#sidebar-item .ant-menu-item-disabled span {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #979797 !important;
}

#sidebar-item .ant-menu-item-disabled span i::before {
  color: #979797 !important;
  font-size: 18px !important;
}
#sidebar-item .ant-menu-item-disabled span i::after {
  //background-image: linear-gradient(to right, #152F4A, #152F4A) !important;
  font-size: 18px !important;
  width: 8px !important;
}
#sidebar-item .ant-menu-item-disabled span i::before {
  //background-image: linear-gradient(to right, #152F4A, #152F4A) !important;
  font-size: 18px !important;
  width: 8px !important;
}
#sidebar-item .ant-menu-item-disabled span i {
  opacity: 1 !important;
  right: 16px !important;
}
.sidebar-a-layout .ant-layout-sider-trigger {
  background-color: #ffffff !important;
  i {
    font-size: 14px !important;
  }
  i svg[fill] {
    fill: #152f4a !important;
  }
}
</style>
