<template>
  <a-dropdown
    :trigger="['click']"
    placement="bottomLeft"
    @visibleChange="addCount"
  >
    <div :class="$style.dropdown">
      <!-- <a-badge :count="count"> -->
      <a-avatar
        shape="square"
        icon="user"
        size="small"
        :class="$style.avatar"
      />
      <!-- </a-badge> -->
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <div>
          <strong
            >{{ user.first_name || '' }}
            {{ user.last_name || '' }}</strong
          >
        </div>
      </a-menu-item>
      <a-menu-item>
        <div v-if="user.role == 'APPSUPERADMIN'">
          <a-menu-divider />
          <div>
            <strong class="mr-1">Company: </strong>
            {{ user.company || '—' }}
          </div>
          <div>
            <strong class="mr-1">Id: </strong>
            {{ user.id || '—' }}
          </div>
          <div>
            <strong class="mr-1">Amplify Env: </strong>
            {{ user.company || '—' }}
          </div>
          <div>
            <strong class="mr-1">User Pool Id: </strong>
            {{ user.pool || '—' }}
          </div>
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.role') }}:</strong>
          {{ user.role || '—' }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
          {{ user.email || '—' }}
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.phone') }}:</strong>
          {{ user.phone || '-' }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <!-- <a-menu-item>
        <router-link to="/tools/settings#general" replace>
          <i class="fe fe-user mr-2"></i>
          {{ $t('topBar.profileMenu.editProfile') }}
        </router-link>
      </a-menu-item> -->
      <a-menu-item @click="goToPreferences()">
        <router-link to="/preferences/organizationProfile" replace>
          <i class="fe fe-settings mr-2"></i>
          {{ 'Preferences' }}
        </router-link>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a @click="logout" href="javascript: void(0);">
          <i class="fe fe-log-out mr-2"></i>
          {{ $t('topBar.profileMenu.logout') }}
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      count: 7,
    }
  },
  methods: {
    addCount() {
      this.count++
    },
    logout() {
      return this.$store.dispatch('user/LOGOUT')
    },
    async goToPreferences() {
      this.$store.commit('CHANGE_MENU_TYPE', { menuType: 'preferences' })
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
