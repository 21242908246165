import {
  listAllDepots,
  getDepotById,
  createDepot,
  updateDepot,
  deleteDepot,
  getDepotsByFilter,
} from '@/services/api/depot/depot'

export default {
  namespaced: true,
  state: {
    allDepots: [],
    depots: [],
    allDepotsLoading: false,
    depotsLoading: false,
    depot: null,
    depotsFilter: [],
  },
  mutations: {
    SET_ALL_DEPOTS(state, depots) {
      state.allDepots = depots
    },
    SET_ALL_DEPOTS_LOADING(state, loading) {
      state.allDepotsLoading = loading
    },
    SET_DEPOTS(state, depots) {
      state.depots = depots
    },
    SET_DEPOT(state, depot) {
      state.depot = depot
    },
    SET_LOADING(state, loading) {
      state.depotsLoading = loading
    },
    SET_DEPOTS_FILTER(state, depots) {
      state.depotsFilter = depots
    },
  },
  actions: {
    async getDepots({ commit }, payload) {
      commit('SET_LOADING', true)
      return listAllDepots(payload)
        .then((response) => {
          commit('SET_DEPOTS', response.data.data)
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Get Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async getDepotById({ commit }, payload) {
      commit('SET_LOADING', true)
      return getDepotById(payload.id)
        .then((response) => {
          commit('SET_DEPOT', response.data)
          return response.data
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get depot data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async createDepot({ commit }, payload) {
      return createDepot(payload)
        .then((depot) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a facility',
              description: 'You have successfully added a new depot',
            },
            { root: true },
          )
          return depot
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: 'Faild to create a facility',
            },
            { root: true },
          )
        })
    },
    async updateDepot({ commit, dispatch }, payload) {
      const { id, data } = payload
      return updateDepot(id, data)
        .then((depot) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Facility updated',
              description: 'You have successfully updated a facility',
            },
            { root: true },
          )
          dispatch('getDepots')
          return depot
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: 'Faild to update a facility',
            },
            { root: true },
          )
        })
    },

    async deleteDepot({ commit }, payload) {
      return deleteDepot(payload)
        .then((depot) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Delete success',
              description: 'You have successfully delete a facility',
            },
            { root: true },
          )
          return depot
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Delete Error',
              description: 'Faild to delete a facility',
            },
            { root: true },
          )
        })
    },
    setDepots({ commit }, payload) {
      commit('SET_DEPOTS', payload)
    },

    async getDepotsByFilter({ commit }, payload) {
      commit('SET_ALL_DEPOTS_LOADING', true)
      return getDepotsByFilter(payload)
        .then((res) => {
          commit('SET_DEPOTS_FILTER', res.data)
          return res
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get depots data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_ALL_DEPOTS_LOADING', false))
    },
  },
  getters: {},
}
