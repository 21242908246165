import apiClient from '@/services/axios'
import _ from 'lodash'

export function getRoutings(query) {
  return apiClient.get(`/routings`, {
    params: query,
  })
}
export function createRouting(routing) {
  return apiClient.post('/routings', routing)
}

export function updateRouting({ id, ...routing }) {
  return apiClient.patch(
    `/routings/${id}`,
    _.omit(routing, 'stops', 'students'),
  )
}

export function getRoutingById(id) {
  return apiClient.get(`/routings/${id}`)
}

export function deleteRouting(id) {
  return apiClient.delete(`/routings/${id}`)
}
