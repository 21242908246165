import { createStop, updateStop, deleteStop } from '@/services/api/stop/stop'

export default {
  namespaced: true,
  state: {
    stops: [],
  },
  mutations: {
    SET_STOPS(state, stops) {
      state.depots = stops
    },
    SET_LOADING(state, loading) {
      state.depotsLoading = loading
    },
  },
  actions: {
    async createStop({ commit }, payload) {
      return createStop(payload)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async updateStop({ commit, rootState }, payload) {
      return updateStop(payload)
        .then((response) => {
          const temp = [...rootState.routings.module.stops]

          const index = temp.findIndex((stop) => stop.id === response.data.id)

          temp[index] = response.data
          commit('routings/SET_STOPS', temp, { root: true })

          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async updateStops({ commit }, payload) {
      const promises = payload.map(async (stop) => {
        const res = await updateStop(stop)
        return res.data
      })

      return Promise.all(promises)
        .then((response) => {
          commit(
            'routings/SET_STOPS',
            response.sort((a, b) => a.index - b.index),
            { root: true },
          )
          return response
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Error',
              description: error.response.data.message,
            },
            { root: true },
          )
        })
    },
    async orderStops({ commit, dispatch }, payload) {
      return dispatch(
        'updateStops',
        payload.map((stop, index) => ({ ...stop, index })),
      )
    },
    async deleteStop({ dispatch, rootState }, payload) {
      try {
        const response = await deleteStop(payload)
        if (response.status === 200) {
          const temp = [...rootState.routings.module.stops]
          const index = temp.findIndex((stop) => stop.id === payload)
          if (index !== -1) {
            temp.splice(index, 1)
            await dispatch('orderStops', temp)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {},
}
