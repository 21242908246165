import apiClient from '@/services/axios'

export async function getStops(query) {
  return apiClient.get('/stops', {
    params: query,
  })
}

export async function createStop(stop) {
  return apiClient.post('/stops', stop)
}

export async function assignStudentToManualStop(payload) {
  return apiClient.post(`/stops/assign-student-to-manual-stop`, payload)
}

export async function unassignStudentFromRouting(payload) {
  return apiClient.post(`/stops/unassign-student-from-routing`, payload)
}

export async function unassignStudentsFromRouting(payload) {
  return apiClient.post(`/stops/unassign-students-from-routing`, payload)
}

export async function updateStop({ id, ...stop }) {
  return apiClient.patch(`/stops/${id}`, _.omit(stop, 'students'))
}

export async function deleteStop(id) {
  return apiClient.delete(`/stops/${id}`)
}
