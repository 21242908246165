<template>
  <a-menu-item
    class="menu-bg"
    v-if="menuInfo.key === 'gotoDashboard'"
    :key="menuInfo.key"
    v-bind="$props"
    v-on="$listeners"
    :disabled="menuInfo.disabled"
    @click="goToDashboard()"
    
  >
    <router-link
      style="margin-top: 0px !important"
      v-if="menuInfo.url"
      :to="menuInfo.url"
    >
      <div v-if="!collapsed" style="padding-top: 4px">
        <i
          v-if="menuInfo.icon"
          class="fe fe-arrow-left"
          style="color: white !important"
        ></i>
      </div>
      <div v-else>
        <!-- <i v-if="menuInfo.icon === 'fe fe-arrow-left'" :class="[styles.icon, menuInfo.icon]"></i> -->
      </div>
      <span style="color: white !important; padding-left: 20px">{{
        menuInfo.title
      }}</span>
      <!-- <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span> -->
    </router-link>
  </a-menu-item>
  <a-menu-item
    v-else
    :key="menuInfo.key + 1"
    v-bind="$props"
    v-on="$listeners"
    :disabled="menuInfo.disabled"
  >
    <router-link v-if="menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{
        menuInfo.count
      }}</span>
      <div v-if="!collapsed">
        <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
      </div>
      <div v-else class="icon-padding">
        <!-- <i
          v-if="menuInfo.icon === 'fe fe-home'"
          :class="[styles.icon, menuInfo.icon]"
        ></i> -->
        <!-- <i
          :class="[styles.icon, menuInfo.icon]"
        ></i> -->
        <div v-if="menuInfo.key === 'welcomeScreen'">
          <home-icon></home-icon>
        </div>
        <div v-else-if="menuInfo.key === 'schematic'">
          <schematic-icon></schematic-icon>
        </div>
        <div v-else-if="menuInfo.key === 'appsCalendar'">
          <calendar-icon></calendar-icon>
        </div>
        <div v-else-if="menuInfo.key === 'toolsAllEmployees'">
          <employee-icon></employee-icon>
        </div>
        <div v-else-if="menuInfo.key === 'vehicles'">
          <vehicle-icon></vehicle-icon>
        </div>
        <div v-else-if="menuInfo.key === 'contracts'">
          <contract-icon></contract-icon>
        </div>
        <div v-else-if="menuInfo.key === 'districts'">
          <district-icon></district-icon>
        </div>
        <div v-else-if="menuInfo.key === 'toolsAllBuildings'">
          <building-icon></building-icon>
        </div>
        <div v-else-if="menuInfo.key === 'students'">
          <students-icon></students-icon>
        </div>
        <div v-else-if="menuInfo.key === 'routes'">
          <routing-icon></routing-icon>
        </div>
        <div v-else-if="menuInfo.key === 'telematics'">
          <telematic-icon></telematic-icon>
        </div>
      </div>
    </router-link>
    <span v-if="!menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{
        menuInfo.count
      }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
    </span>
  </a-menu-item>
</template>

<script>
import BuildingIcon from '@/components/svgicons/BuildingIcon.vue'
import CalendarIcon from '@/components/svgicons/CalendarIcon.vue'
import ContractIcon from '@/components/svgicons/ContractIcon.vue'
import DistrictIcon from '@/components/svgicons/DistrictIcon.vue'
import EmployeeIcon from '@/components/svgicons/EmployeeIcon.vue'
import HomeIcon from '@/components/svgicons/HomeIcon.vue'
import PlusIcon from '@/components/svgicons/PlusIcon.vue'
import RoutingIcon from '@/components/svgicons/RoutingIcon.vue'
import SchematicIcon from '@/components/svgicons/SchematicIcon.vue'
import StudentsIcon from '@/components/svgicons/StudentsIcon.vue'
import TelematicIcon from '@/components/svgicons/TelematicIcon.vue'
import VehicleIcon from '@/components/svgicons/VehicleIcon.vue'
import { Menu } from 'ant-design-vue'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: Object,
    styles: Object,
    collapsed: Boolean,
  },
  components: {
    PlusIcon,
    HomeIcon,
    SchematicIcon,
    CalendarIcon,
    EmployeeIcon,
    VehicleIcon,
    ContractIcon,
    DistrictIcon,
    BuildingIcon,
    StudentsIcon,
    RoutingIcon,
    TelematicIcon
  },
  methods: {
    goToDashboard() {
      if (['gotoDashboard'].includes(this.menuInfo.key)) {
        this.$store.commit('CHANGE_MENU_TYPE', { menuType: 'main' })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.menu-bg {
  background: #1f7396 !important;
  color: white !important;
  display: flex;
  gap: 20px;
  padding-left: 10px;
  align-items: center;
  .link {
    margin-top: 0px !important;
    display: flex !important;
    align-items: center !important;
  }
}
.cl-white {
  color: white !important;
}
.icon-padding {
  padding-inline: 10px;
}
</style>
