import apiClient from '@/services/axios'

export function getSpecializedRoutings(query) {
  return apiClient.get(`/specialized-routings`, {
    params: query,
  })
}

export function createSpecializedRouting(specializedRouting) {
  return apiClient.post('/specialized-routings', specializedRouting)
}

export function updateSpecializedRouting({ id, ...specializedRouting }) {
  return apiClient.patch(`/specialized-routings/${id}`, specializedRouting)
}

export function updateSpecializedStop({ id, ...specializedStop }) {
  return apiClient.patch(`/specialized-stops/${id}`, specializedStop)
}

export function getSpecializedRoutingById(id) {
  return apiClient.get(`/specialized-routings/${id}`)
}

export function deleteSpecializedRouting(id) {
  return apiClient.delete(`/specialized-routings/${id}`)
}
