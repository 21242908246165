import apiClient from '@/services/axios'

/**
 * Create a contract
 *
 * @param {Contract} contact
 * @returns contract
 */
export function createContract(payload) {
  return apiClient.post('/contracts', payload)
}

/**
 * Update a contract
 *
 * @param {Contract} contract
 * @returns
 */
export async function updateContract(id, contractData) {
  return await apiClient.patch(`/contracts/${id}`, contractData)
}

export async function deleteContract(id) {
  return apiClient.delete(`/contracts/${id}`)
}

export function listAllContract() {
  return apiClient.get('/contracts')
}

export async function getContractById(id) {
  try {
    const response = await apiClient.get(`/contracts/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'Contract data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `Contract with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching contract by ID',
      error,
    }
  }
}

export async function updateContractRule(id, updatedData) {
  return apiClient.patch(`/contracts/${id}/rules`, updatedData)
}

export async function uploadExistingContractData(data) {
  try {
    for (let s = 0; s < data.length; s++) {
      const element = data[s]

      const toUploadData = {
        contractName: element.contractName.trim() || "",
        contractAbbr: element.contractAbbreviation.trim() || "",
        contractUrl: element.contractUrl? element.contractUrl.trim() : "",
        onBoard: {
          cameras: element.cameras === "Yes",
          gpsTracking: element.gpsTracking === "Yes",
          digitalMonitor: element.digitalMonitor === "Yes",
          airConditioning: element.airConditioning === "Yes"
        },
        powerTrain: {
          diesel: element.diesel === "Yes",
          gas: element.gas === "Yes",
          hybrid: element.hybrid === "Yes",
          electric: element.electric === "Yes",
          hydrogen: element.hydrogen === "Yes"
        },
        training: {
          childBehavioral: element.childBehaviorial === "Yes",
          privateNurse: element.privateNurse === "Yes",
          independentAid: element.independentAid === "Yes",
          cpr: element.cpr === "Yes",
          epiPen: element.epiPen === "Yes"
        },
        districtIds: [],
        maxVehicleAge: parseInt(element.maxVehicleAge, 10) || 10,
        maxVehicleMilage: parseInt(element.maxVehicleMileage.replace(/,/g, ''), 10) || 100000 
      };

      const uploadContractData = await createContract(toUploadData)

      if (s === data.length - 1) {
        return {
          status: 200,
          message: "Contracts uploaded successfully",
        }
      }
    }
  } catch (error) {
    console.error(error)
    return {
      status: 404,
      message: "Something went wrong",
    }
  }
}