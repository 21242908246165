<template>
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="5.70312"
      width="24"
      height="20"
      rx="2"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M1 9.70313C1 7.81751 1 6.8747 1.58579 6.28891C2.17157 5.70312 3.11438 5.70312 5 5.70312H21C22.8856 5.70312 23.8284 5.70312 24.4142 6.28891C25 6.8747 25 7.81751 25 9.70312V11.0365H1V9.70313Z"
      fill="currentColor"
    />
    <path
      d="M6.33203 1.70312L6.33203 5.70313"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M19.668 1.70312L19.668 5.70313"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <rect
      x="6.33203"
      y="13.7031"
      width="5.33333"
      height="2.66667"
      rx="0.5"
      fill="currentColor"
    />
    <rect
      x="6.33203"
      y="19.0391"
      width="5.33333"
      height="2.66667"
      rx="0.5"
      fill="currentColor"
    />
    <rect
      x="14.332"
      y="13.7031"
      width="5.33333"
      height="2.66667"
      rx="0.5"
      fill="currentColor"
    />
    <rect
      x="14.332"
      y="19.0391"
      width="5.33333"
      height="2.66667"
      rx="0.5"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
