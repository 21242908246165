/**
 * toast notification module
 */
import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  mutations: {
    NEW(state, { type, message, description }) {
      if (type === 'error') {
        console.error(message)
      }

      Vue.prototype.$notification[type]({
        message: message,
        description: description,
      })
    },
  },
}
