import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles
import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
import VueLayers from 'vuelayers'
import 'vuelayers/dist/vuelayers.css' // needs css-loader
import 'vuelayers/src/styles/main.scss' //
import 'vue-select/dist/vue-select.css'
import BootstrapVue from 'bootstrap-vue'
import VueTippy, { TippyComponent } from 'vue-tippy'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'

import App from './App.vue'
import router from './router'
import { Amplify } from 'aws-amplify'
import { DataStore } from 'aws-amplify/datastore'
import { ConsoleLogger } from 'aws-amplify/utils'
import Mapbox from 'mapbox-gl'
import * as VueMapbox from 'vue-mapbox'
import VueFormulate from '@braid/vue-formulate'
import FormulateVSelectPlugin from '@cone2875/vue-formulate-select'
import amplifyconfig from './amplifyconfiguration.json'
import store from './store'
import { i18n } from './localization'
import './antd'
import './registerServiceWorker'
import { createPinia, PiniaVuePlugin } from 'pinia'
import AsyncComputed from 'vue-async-computed'
import Print from 'vue-print-nb'
Vue.use(Print)

Amplify.configure(amplifyconfig)

// ConsoleLogger.LOG_LEVEL = 'DEBUG'

Vue.use(Amplify)
Vue.use(DataStore)
Vue.use(BootstrapVue)
Vue.use(Mapbox)
Vue.use(VueMapbox)
// all input/output coordinates, GeoJSON features in EPSG:4326 projection
Vue.use(VueLayers, {
  dataProjection: 'EPSG:4326',
})
Vue.use(NProgress)
Vue.use(VueFormulate, {
  plugins: [FormulateVSelectPlugin],
})
Vue.use(VuePageTitle, {
  prefix: 'Routr | ',
  router,
})
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(AsyncComputed)

Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
    },
  },
})

Vue.component('tippy', TippyComponent)

Vue.config.productionTip = false
const nprogress = new NProgress({ parent: 'body' })
const eventBus = new Vue()

export default eventBus

Vue.prototype.$eventBus = eventBus

// gloval directive
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

new Vue({
  router,
  store,
  nprogress,
  i18n,
  pinia,
  render: (h) => h(App),
}).$mount('#app')
