import apiClient from '@/services/axios'

export async function listAllTelematics() {
  return apiClient.get(`/telematics`)
}

export async function findOneTelematic(imeiNumber) {
  return await apiClient.get(`/telematics/${imeiNumber}`)
}

export async function getTelematicsByFilter(filter) {
  const {
    deviceId,
    startDate,
    endDate,
    startTime,
    endTime,
    timeInterval,
    utcOffset,
  } = filter
  return await apiClient.get(
    `/telematics/${deviceId}/${startDate}/${endDate}/${startTime}/${endTime}/${timeInterval}/${utcOffset}`,
  )
}

export async function getAddressWithGeocode(batch) {
  return await apiClient.post('/telematics/geocode', batch)
}
