<template>
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13.7031C1 11.46 1 10.3385 1.48231 9.50313C1.79827 8.95586 2.25273 8.5014 2.8 8.18543C3.63538 7.70312 4.75692 7.70312 7 7.70312H19C21.2431 7.70312 22.3646 7.70312 23.2 8.18543C23.7473 8.5014 24.2017 8.95586 24.5177 9.50313C25 10.3385 25 11.46 25 13.7031C25 15.9462 25 17.0677 24.5177 17.9031C24.2017 18.4504 23.7473 18.9049 23.2 19.2208C22.3646 19.7031 21.2431 19.7031 19 19.7031H7C4.75692 19.7031 3.63538 19.7031 2.8 19.2208C2.25273 18.9049 1.79827 18.4504 1.48231 17.9031C1 17.0677 1 15.9462 1 13.7031Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M21.4016 4.10313C21.4016 2.77764 20.327 1.70312 19.0016 1.70312H7.00156C5.67608 1.70312 4.60156 2.77764 4.60156 4.10313"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M21.4016 23.3047C21.4016 24.6302 20.327 25.7047 19.0016 25.7047H7.00156C5.67608 25.7047 4.60156 24.6302 4.60156 23.3047"
      stroke="currentColor"
      stroke-width="2"
    />
    <rect
      x="5.20156"
      y="11.9047"
      width="6"
      height="1.2"
      rx="0.6"
      stroke="currentColor"
      stroke-width="1.2"
    />
  </svg>
</template>

<script>
export default {
  name: 'DistrictIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
