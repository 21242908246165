<template>
  <svg
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.391 20.7146L12.8389 21.6087L12.8389 21.6087L12.391 20.7146ZM11.6029 20.7146L12.0507 19.8204L12.0507 19.8204L11.6029 20.7146ZM19.5525 10.1031C19.5525 12.7907 18.1333 14.9911 16.4044 16.6742C14.6784 18.3545 12.7399 19.4214 11.9432 19.8204L12.8389 21.6087C13.7226 21.166 15.8649 19.9906 17.7995 18.1073C19.7312 16.2267 21.5525 13.5422 21.5525 10.1031H19.5525ZM11.997 2.70312C16.1872 2.70312 19.5525 6.03344 19.5525 10.1031H21.5525C21.5525 4.89443 17.257 0.703125 11.997 0.703125V2.70312ZM4.44141 10.1031C4.44141 6.03344 7.80677 2.70312 11.997 2.70312V0.703125C6.73695 0.703125 2.44141 4.89443 2.44141 10.1031H4.44141ZM12.0507 19.8204C11.2541 19.4214 9.31556 18.3545 7.58954 16.6742C5.86062 14.9911 4.44141 12.7907 4.44141 10.1031H2.44141C2.44141 13.5422 4.26273 16.2267 6.19444 18.1073C8.12906 19.9906 10.2713 21.166 11.1551 21.6087L12.0507 19.8204ZM11.9432 19.8204C11.9559 19.8141 11.9747 19.8086 11.997 19.8086C12.0192 19.8086 12.038 19.8141 12.0507 19.8204L11.1551 21.6087C11.6874 21.8753 12.3066 21.8753 12.8389 21.6087L11.9432 19.8204ZM14.6615 10.1C14.6615 11.5187 13.4849 12.7 11.9948 12.7V14.7C14.5547 14.7 16.6615 12.6577 16.6615 10.1H14.6615ZM11.9948 7.5C13.4849 7.5 14.6615 8.68128 14.6615 10.1H16.6615C16.6615 7.54227 14.5547 5.5 11.9948 5.5V7.5ZM9.32812 10.1C9.32812 8.68128 10.5047 7.5 11.9948 7.5V5.5C9.43484 5.5 7.32812 7.54227 7.32812 10.1H9.32812ZM11.9948 12.7C10.5047 12.7 9.32812 11.5187 9.32812 10.1H7.32812C7.32812 12.6577 9.43484 14.7 11.9948 14.7V12.7Z"
      fill="currentColor"
    />
    <path
      d="M21.5263 20.3C22.4917 20.8473 23 21.4681 23 22.1C23 22.7319 22.4917 23.3527 21.5263 23.9C20.5608 24.4473 19.1722 24.9017 17.5 25.2177C15.8278 25.5337 13.9309 25.7 12 25.7C10.0691 25.7 8.17221 25.5337 6.5 25.2177C4.82779 24.9017 3.43917 24.4473 2.47372 23.9C1.50827 23.3527 1 22.7319 1 22.1C1 21.4681 1.50827 20.8473 2.47372 20.3"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'TelematicIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
