import {
  createEmployee,
  getEmployees,
  updateEmployee,
  deleteEmployee,
  deleteEmployees,
} from '@/services/api/employee/employee'

export default {
  namespaced: true,
  state: {
    allEmployees: [],
    employees: [],
    allEmployeesLoading: false,
    employeesLoading: false,
  },
  mutations: {
    SET_EMPLOYEES(state, employees) {
      state.employees = employees
    },
    SET_LOADING(state, loading) {
      state.employeesLoading = loading
    },
  },
  actions: {
    async getEmployees({ commit }, payload) {
      commit('SET_LOADING', true)
      return getEmployees(payload)
        .then((res) => {
          commit('SET_EMPLOYEES', res.data.employees)
          return res
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get employees data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    // async getEmployees({ commit }, payload) {
    //   commit('SET_LOADING', true)
    //   return getEmployees(payload)
    //     .then((response) => {
    //       console.log(response, 'response in the employee state')
    //       commit('SET_EMPLOYEES', response.data.result.data)
    //       return response
    //     })
    //     .catch((error) => {
    //       const errorMessage = error.response.data.message
    //       commit(
    //         'notification/NEW',
    //         {
    //           type: 'warning',
    //           message: 'Get Error',
    //           description: errorMessage.join('\n'),
    //         },
    //         { root: true },
    //       )
    //     })
    //     .finally(() => commit('SET_LOADING', false))
    // },
    async createEmployee({ commit }, payload) {
      return createEmployee(payload)
        .then((employee) => {
          if (!employee) return false
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a employee',
              description: 'You have successfully added a new employee',
            },
            { root: true },
          )
          return employee
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async updateEmployee({ commit }, payload) {
      const { id, data } = payload
      return updateEmployee(id, data)
        .then((employee) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Employee updated',
              description: 'You have successfully updated the employee',
            },
            { root: true },
          )
          return employee
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async deleteEmployee({ commit }, payload) {
      return deleteEmployee(payload)
        .then((employee) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Employee deleted',
              description: 'You have successfully deleted a employee',
            },
            { root: true },
          )
          return employee
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a employee',
            },
            { root: true },
          )
        })
    },
    async deleteEmployees({ commit }, payload) {
      return deleteEmployees(payload)
        .then((employee) => {
          return employee
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a employees',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
