import apiClient from '@/services/axios'

export function createRoutingPackage(routingPackage) {
  return apiClient.post(`/routing-package`, routingPackage)
}

export function updateRoutingPackage(id, routingPackage) {
  return apiClient.patch(`/routing-package/${id}`, routingPackage)
}

export function listAllRoutingPackage(query) {
  return apiClient.get(`/routing-package`, {
    params: query,
  })
}

export async function getRoutingPackageById(id) {
  return await apiClient.get(`/routing-package/${id}`)
}

export async function deleteRoutingPackage(id) {
  return await apiClient.delete(`/routing-package/${id}`)
}
