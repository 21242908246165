import axios from 'axios'
import router from '@/router'
import Vue from 'vue'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

apiClient.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.config.url != '/users/me'
    ) {
      Vue.prototype.$notification.warning({
        message: 'Failed',
        description: 'Session has been expired. Please try again.',
      })
      router.push('/auth/login')
    }
    return Promise.reject(error)
  },
)

export default apiClient
