import apiClient from '@/services/axios'

export async function listAllOrganizations() {
  return apiClient.get('/organizations')
}

export async function getOrganizationByID(id) {
  try {
    const response = await apiClient.get(`/organizations/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'Organization data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `Organization with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching Organization by ID',
      error,
    }
  }
}

export function createOrganization(organization) {
  return apiClient.post('/organizations', organization)
}

export async function uploadLogo(organizationId, file) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('id', organizationId);

    return await apiClient.post('/organizations/uploadPfp', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
}

export async function updateOrganization(id, organizationData) {
  return apiClient.patch(`/organizations/${id}`, organizationData)
}

export async function deleteOrganization(id) {
  return apiClient.delete(`/organizations/${id}`)
}
