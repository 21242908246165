import {
  createContract,
  deleteContract,
  listAllContract,
  updateContract,
  updateContractRule
} from '@/services/api/contract/contract'

export default {
  namespaced: true,
  state: {
    allContracts: [],
    contracts: [],
    allContractsLoading: false,
    contractsLoading: false,
  },
  mutations: {
    SET_ALL_CONTRACTS(state, contracts) {
      state.allContracts = contracts
    },
    SET_ALL_CONTRACTS_LOADING(state, loading) {
      state.allContractsLoading = loading
    },
    SET_CONTRACTS(state, contracts) {
      state.contracts = contracts
    },
    SET_LOADING(state, loading) {
      state.contractsLoading = loading
    },
  },
  actions: {
    async getContracts({ commit }, payload) {
      commit('SET_LOADING', true)
      return listAllContract()
        .then((contracts) => {
          commit('SET_CONTRACTS', contracts.data)
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get contracts data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async updateContract({ commit }, payload) {
      const { id, data } = payload
      return updateContract(id, data)
        .then((contract) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Contract updated',
              description: 'You have successfully updated the contract',
            },
            { root: true },
          )
          return contract
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },

    async updateContractRule({ commit }, payload) {
      const { id, data } = payload
      return updateContractRule(id, data)
        .then((contract) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Contract rule updated',
              description: 'You have successfully updated a contract',
            },
            { root: true },
          )
          return contract
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: 'Faild to update a contract',
            },
            { root: true },
          )
        })
    },

    async createContract({ commit }, payload) {
      return createContract(payload)
        .then((contract) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a contract',
              description: 'You have successfully added a new contract',
            },
            { root: true },
          )
          return contract
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async deleteContract({ commit }, payload) {
      return deleteContract(payload.id)
        .then((contract) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Contract deleted',
              description: 'You have successfully deleted a contract',
            },
            { root: true },
          )
          return contract
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Delete Error',
              description: 'Faild to delete a contract',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
