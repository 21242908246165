import apiClient from '@/services/axios'

export function getRoutingColors() {
  return apiClient.get(`/routing-colors`)
}

export function getRoutingColorById(id) {
  return apiClient.get(`/routing-colors/${id}`)
}

export function createRoutingColor(routingColor) {
  return apiClient.post(`/routing-colors`, routingColor)
}

export function updateRoutingColor(routingColor) {
  return apiClient.patch(`/routing-colors/${routingColor.id}`, routingColor)
}
