import apiClient from '@/services/axios'
import { listAllDistricts } from '../district/district'
import { geocode } from '@/utils'

export function getAllBuildings() {
  return apiClient.get('/buildings')
}

export function getBuildings(filter) {
  return apiClient.post('/buildings/searchBuildings', filter)
}

export function createBuilding(building) {
  return apiClient.post('/buildings', building)
}

export function updateBuilding({ id, ...building }) {
  return apiClient.patch(`/buildings/${id}`, building)
}

export function getBuildingById(id) {
  return apiClient.get(`/buildings/${id}`)
}

/**
 * delete a building
 * @param {number} id
 * @returns
 */
export async function deleteBuilding(id) {
  return apiClient.delete(`/buildings/${id}`)
}

// TODO: create newly
export async function uploadExistingBuildingData(data) {
  try {
    const response = await listAllDistricts()
    const districts = response.data
    for (let s = 0; s < data.length; s++) {
      const element = data[s]
      const coordinates = await geocode(element.address.trim() || '')
      const matchingDistrict = districts.find(
        (district) => district.districtName === element.assignedDistrict.trim(),
      )

      let processedGrades = element.grades.trim()
      if (processedGrades === '12+') {
        processedGrades = '13'
      } else if (!processedGrades.includes('-')) {
        processedGrades = `${processedGrades}-${processedGrades}`
      }
      const toUploadData = {
        schoolYearActive: element.schoolYearActive.trim() || 'YES',
        summerActive: element.summerActive.trim() || 'NO',
        address: element.address.trim() || '',
        districtId: matchingDistrict ? matchingDistrict.id : '',
        buildingName: element.buildingName.trim() || '',
        buildingAbbr: element.buildingAbbreviation.trim() || '',
        buildingUrl: element.buildingUrl ? element.buildingUrl.trim() : '',
        buildingICalUrl: element.buildingICalUrl
          ? element.buildingICalUrl.trim()
          : '',
        bellTimeAM: element.bellTimeAM.trim() || '9:00',
        bellTimePM: element.bellTimePM.trim() || '3:15',
        grades: processedGrades || 'K-12',
        lat: coordinates.lat || 0,
        long: coordinates.long || 0,
      }

      const uploadDistrictData = await createBuilding(toUploadData)

      if (s === data.length - 1) {
        return {
          status: 200,
          message: 'Districts uploaded successfully',
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}
