<template>
  <a-layout>
    <div
      :style="{
        backgroundImage: `url(resources/images/new-ui/login-background.svg)`,
      }"
      class="auth-container"
    >
      <a-layout-content>
        <!-- <cui-support-chat /> -->
        <div
          :class="{
            [$style.container]: true,
            cui__layout__squaredBorders: settings.isSquaredBorders,
            cui__layout__cardsShadow: settings.isCardShadow,
            cui__layout__borderless: settings.isBorderless,
            [$style.white]: settings.authPagesColor === 'transparent',
            [$style.gray]: settings.authPagesColor === 'gray',
          }"
          :style="{
            backgroundImage:
              settings.authPagesColor === 'image'
                ? `url(resources/images/content/photos/7.jpg)`
                : 'none',
          }"
        >
          <div :class="$style.containerInner">
            <transition :name="settings.routerAnimation" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </a-layout-content>
    </div>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuthLayout',
  components: {}, // CuiSupportChat
  computed: mapState(['settings']),
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
