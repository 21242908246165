import {
  createStudent,
  getStudents,
  updateStudent,
  deleteStudent,
  deleteStudents,
  resetAmPickupAddress,
  resetPmDropoffAddress,
  removeAffliatedAddress,
  updateAffliatedAddress,
  getStudentsByName,
} from '@/services/api/student/student'

export default {
  namespaced: true,
  state: {
    allStudents: [],
    students: [],
    allStudentsLoading: false,
    studentsLoading: false,
  },
  mutations: {
    SET_ALL_STUDENTS(state, students) {
      state.allStudents = students
    },
    SET_ALL_STUDENTS_LOADING(state, loading) {
      state.allStudentsLoading = loading
    },
    SET_STUDENTS(state, students) {
      state.students = students
    },
    SET_LOADING(state, loading) {
      state.studentsLoading = loading
    },
  },
  actions: {
    async getStudents({ commit }, payload) {
      commit('SET_LOADING', true)
      return getStudents(payload)
        .then((res) => {
          commit('SET_STUDENTS', res.data.students)
          return res
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get students data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async getStudentsByName({ commit }, payload) {
      return getStudentsByName(payload)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async createStudent({ commit }, payload) {
      return createStudent(payload)
        .then((student) => {
          if (!student) return false
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a student',
              description: 'You have successfully added a new student',
            },
            { root: true },
          )
          return student
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async updateStudent({ commit, rootState }, payload) {
      const { id, data, updateRoutingStudents } = payload
      return updateStudent(id, data)
        .then((response) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Student updated',
              description: 'You have successfully updated the student',
            },
            { root: true },
          )
          if (updateRoutingStudents) {
            const temp = [...rootState.routings.students]
            const index = temp.findIndex((student) => student.id === id)
            temp[index] = response.data
            commit('routings/SET_STUDENTS', temp, { root: true })
          }
          return response.data
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async resetAmPickupAddress({ commit }, id) {
      return resetAmPickupAddress(id)
        .then((student) => {
          return student
        })
        .catch((error) => {
          throw error
        })
    },
    async resetPmDropoffAddress({ commit }, id) {
      return resetPmDropoffAddress(id)
        .then((student) => {
          return student
        })
        .catch((error) => {
          throw error
        })
    },
    async updateAffliatedAddress({ commit, rootState }, payload) {
      const { id, data, updateRoutingStudents } = payload
      return updateAffliatedAddress(id, data)
        .then((response) => {
          if (updateRoutingStudents) {
            const temp = [...rootState.routings.students]
            const index = temp.findIndex(
              (student) => student.id === response.data.id,
            )
            temp[index] = response.data
            commit('routings/SET_STUDENTS', temp, { root: true })
          }

          return response.data
        })
        .catch((error) => {
          throw error
        })
    },
    async removeAffliatedAddress({ commit }, id) {
      return removeAffliatedAddress(id).catch((error) => {
        throw error
      })
    },
    async deleteStudent({ commit }, payload) {
      return deleteStudent(payload)
        .then((student) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Student deleted',
              description: 'You have successfully deleted a student',
            },
            { root: true },
          )
          return student
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a student',
            },
            { root: true },
          )
        })
    },
    async deleteStudents({ commit }, payload) {
      return deleteStudents(payload)
        .then((student) => {
          // commit(
          //   'notification/NEW',
          //   {
          //     type: 'success',
          //     message: 'Students deleted',
          //     description: 'You have successfully deleted a students',
          //   },
          //   { root: true },
          // )
          return student
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a students',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
