import apiClient from '@/services/axios'

export async function login(user) {
  return apiClient
    .post('/auth/signin', {
      email: user.email,
      password: user.password,
    })
    .then(async (response) => {
      if (response?.data?.success) {
        const { userId, accessToken } = response.data
        // await verifyEmail(userId)
        return { success: true, userId: userId, accessToken: accessToken }
      }
      return { success: false, userId: '' }
    })
    .catch((err) => console.log(err))
}

export async function register(user) {
  return apiClient.post('/auth/signup', {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: user.password,
    role: Number(user.role),
  })
}

export async function currentAccount() {
  return apiClient
    .get('/users/me')
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function verifyEmail(userId) {
  return apiClient
    .post('/auth/verifyEmail', {
      userId: userId,
    })
    .then(() => {
      return { success: true }
    })
    .catch((err) => console.log(err))
}
export async function forgotPassword(email) {
  return apiClient
    .post('/auth/forgotPassword', {
      email: email,
    })
    .then(() => {
      return { success: true }
    })
    .catch((err) => console.log(err))
}

export async function resendCode(data) {
  return apiClient
    .post('/auth/verifyEmail', {
      userId: data.userId,
    })
    .then((response) => {
      if (response.data.success) {
        return { success: true }
      } else {
        return { success: false }
      }
    })
    .catch((err) => console.log(err))
}

export async function verifyToken(token) {
  return apiClient.post('/auth/verifyToken', {
    token,
  })
}
export async function resetPassword(data) {
  return apiClient.post('/auth/resetPassword', data)
}

export async function verifyEmailCode(data) {
  return apiClient
    .post('/auth/verifyEmailCode', {
      userId: data.userId,
      code: data.code,
      trustDevice: data.trustDevice,
    })
    .then((response) => {
      if (response?.data?.success) {
        const { accessToken } = response.data
        return { success: true, accessToken: accessToken.accessToken }
      }
      return { success: false, accessToken: '' }
    })
    .catch((err) => console.log(err))
}

export async function logout() {
  return apiClient
    .post('/auth/signout')
    .then(() => {
      localStorage.removeItem('accessToken')
      return true
    })
    .catch((err) => console.log(err))
}
