<template>
  <svg
    width="25"
    height="27"
    viewBox="0 0 25 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="10.9038"
      cy="7.03646"
      rx="5.12641"
      ry="5.33333"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1714 17.3724C13.821 16.9316 12.3713 16.7031 10.9037 16.7031C8.48107 16.7031 6.10694 17.3258 4.14852 18.4983C2.18975 19.6711 0.7273 21.3485 0.0544853 23.3078C-0.12489 23.8301 0.153141 24.399 0.675485 24.5784C1.19783 24.7577 1.76668 24.4797 1.94606 23.9574C2.44155 22.5145 3.55349 21.1857 5.17591 20.2143C6.79868 19.2427 8.8124 18.7031 10.9037 18.7031C10.9272 18.7031 10.9507 18.7032 10.9741 18.7033C11.5125 17.9007 12.4282 17.3724 13.4673 17.3724L15.1714 17.3724ZM21.5937 24.2503C21.7828 24.0091 21.8575 23.6857 21.7728 23.3724H21.5937V24.2503Z"
      fill="currentColor"
    />
    <path
      d="M18.5938 15.0391L18.5938 25.7057"
      stroke="currentColor"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <path
      d="M23.7188 20.3672L13.4659 20.3672"
      stroke="currentColor"
      stroke-width="2.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'EmployeeIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
