import Vue from 'vue'
import apiClient from '@/services/axios'

export function createRoutingVehicle(payload) {
  return apiClient.post('/routing-vehicles', payload)
}

export function updateRoutingVehicle({ id, ...payload }) {
  return apiClient.patch(`/routing-vehicles/${id}`, payload)
}

export function deleteRoutingVehicle(id) {
  return apiClient.delete(`/routing-vehicles/${id}`)
}
