import apiClient from '@/services/axios'

export function createRoutingGroup(routingGroup) {
  return apiClient.post('/routing-groups', routingGroup)
}

export function getRoutingGroups(query) {
  return apiClient.get(`/routing-groups`, {
    params: query,
  })
}
