import {
  createSpecializedRouting,
  getSpecializedRoutings,
  getSpecializedRoutingById,
  updateSpecializedRouting,
  deleteSpecializedRouting,
  updateSpecializedStop,
} from '@/services/api/specialized-routing/specialized-routing'

export default {
  namespaced: true,
  state: {
    loading: false,
    specializedRoutings: [],
    currentSpecializedRouting: {},
    specializedStartTime: null, // this is used to record when user starts the routing
  },
  mutations: {
    SET_SPECIALIZED_ROUTINGS(state, specializedRoutings) {
      state.specializedRoutings = specializedRoutings
    },
    SET_CURRENT_SPECIALIZED_ROUTING(state, currentSpecializedRouting) {
      if (
        currentSpecializedRouting != undefined &&
        currentSpecializedRouting != null
      ) {
        state.currentSpecializedRouting = currentSpecializedRouting
      }
    },
    SET_SPECIALIZED_START_TIME(state, specializedStartTime) {
      state.specializedStartTime = specializedStartTime
    },
  },
  actions: {
    async getSpecializedRoutings({ commit }, payload) {
      return getSpecializedRoutings(payload)
        .then((response) => {
          const sortedRoutings = response.data.data.map((routing) => {
            return {
              ...routing,
              stops: [...routing.stops].sort((a, b) => a.index - b.index), // Sort stops by index
            }
          })

          commit('SET_SPECIALIZED_ROUTINGS', sortedRoutings)
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getSpecializedRoutingById({ commit }, payload) {
      return getSpecializedRoutingById(payload)
        .then((response) => {
          const specializedRouting = response.data

          // Sort stops by index
          specializedRouting.stops.sort((a, b) => a.index - b.index)

          commit('SET_CURRENT_SPECIALIZED_ROUTING', specializedRouting)
          return specializedRouting
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async createSpecializedRouting({ commit }, payload) {
      return createSpecializedRouting(payload)
        .then((response) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Success',
              description: 'Specialized Routing Created Successfully',
            },
            { root: true },
          )
          return response.data
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to create a routing',
            },
            { root: true },
          )
        })
    },
    async updateSpecializedRouting({ commit }, payload) {
      return updateSpecializedRouting(payload)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to update a routing',
            },
            { root: true },
          )
          console.error(error)
        })
    },
    async updateSpecializedStop({ commit }, payload) {
      return updateSpecializedStop(payload)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to update a stop',
            },
            { root: true },
          )
          console.error(error)
        })
    },
    async deleteSpecializedRouting({ commit }, payload) {
      return deleteSpecializedRouting(payload)
        .then(() => {})
        .catch((error) => {
          console.error(error)
          throw error
        })
    },
    setSpecializedStartTime({ commit }, payload) {
      commit('SET_SPECIALIZED_START_TIME', payload)
    },
  },
  getters: {},
}
