import axios from 'axios'
import { mapboxAccessToken, PLACES_URL } from '@/constants'

export const placeToCoordinates = (text) =>
  axios(
    `${PLACES_URL}/${text}.json?country=US&limit=1&access_token=${mapboxAccessToken}`,
  )
export const coordinatesToPlaceName = (lng, lat) =>
  axios(
    `${PLACES_URL}/${lng},${lat}.json?country=US&limit=2&types=address&access_token=${mapboxAccessToken}`,
  )

export const coordinatesToPlaceNameWithoutCountry = (lng, lat) =>
  axios(
    `${PLACES_URL}/${lng},${lat}.json?limit=1&access_token=${mapboxAccessToken}`,
  ).then((response) => {
    const { features } = response.data
    if (features.length > 0) {
      const { place_name } = features[0]
      const addressComponents = place_name.split(',').slice(0, -1).join(',')
      return addressComponents
    }
    return null
  })
