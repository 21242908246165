<template>
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 10.1031L13 1.70312L25 10.1031V23.3031C25 24.6286 23.8061 25.7031 22.3333 25.7031H3.66667C2.19391 25.7031 1 24.6286 1 23.3031V10.1031Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 25.7031V13.7031H17V25.7031"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
