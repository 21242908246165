import apiClient from '@/services/axios'

export async function createStudentHousehold(payload) {
  return await apiClient.post('/student-households', payload)
}

export async function createStudentHouseholdArray(payload) {
  return await apiClient.post('/student-households/array', payload)
}

export async function getStudentHouseholdById(id) {
  try {
    const response = await apiClient.get(`/student-households/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'StudentHousehold data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `StudentHousehold with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching StudentHousehold by ID',
      error,
    }
  }
}

export async function updateStudentHousehold(id, studentHouseholdData) {
  return await apiClient.patch(
    `/student-households/${id}`,
    studentHouseholdData,
  )
}

export async function deleteStudentHousehold(id) {
  return await apiClient.delete(`/student-households/${id}`)
}
