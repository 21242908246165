<template>
  <div :class="$style.topbar">
    <div class="mr-4"></div>
    <div class="mr-auto"></div>
    <div class="mr-4 d-none d-sm-block">
      <!-- <cui-dark-theme /> -->
    </div>
    <div v-if="user.role == 'APPSUPERADMIN'">
      <div class="ml-3 mr-3">
        <router-link to="/advanced/activity" replace>
          <i class="fe fe-activity" :class="$style.icon"></i>
        </router-link>
      </div>
    </div>
    <div v-if="user.role == 'APPSUPERADMIN'">
      <div class="ml-3 mr-3">
        <router-link to="/tools/adminpanels" replace>
          <i class="fe fe-grid" :class="$style.icon"></i>
        </router-link>
      </div>
    </div>
    <!-- <div class="ml-3 mr-5">
      <router-link to="/preferences/profile" replace>
      <i
        class="fe fe-settings"
        :class="$style.icon"
      ></i>
      </router-link>
    </div> -->
    <div class="mr-4 d-none d-sm-block">
      <cui-actions />
    </div>
    <div>
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
import CuiDarkTheme from './DarkTheme'
import CuiActions from './Actions'
import CuiUserMenu from './UserMenu'
import { mapState } from 'vuex'

export default {
  components: {
    CuiDarkTheme,
    CuiActions,
    CuiUserMenu,
  },
  computed: {
    ...mapState(['user']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  mounted() {
    console.log('user', this.user)
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
