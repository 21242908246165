import {
  createEmergencyContact,
  updateEmergencyContact,
  deleteEmergencyContact,
  createEmergencyContactArray
} from '@/services/api/emergency-contact/emergency-contact'

export default {
  namespaced: true,
  state: {
    allEmergencyContacts: [],
    emergencyContacts: [],
    allEmergencyContactsLoading: false,
    emergencyContactsLoading: false,
  },
  mutations: {},
  actions: {
    async createEmergencyContact({ commit }, payload) {
      return createEmergencyContact(payload)
        .then((emergencyContact) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a Emergency Contact',
              description: 'You have successfully added a new Emergency Contact',
            },
            { root: true },
          )
          return emergencyContact
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async createEmergencyContactArray({ commit }, payload) {
      return createEmergencyContactArray(payload)
      .then((emergencyContacts) => {
        commit(
          'notification/NEW',
          {
            type: 'success',
            message: 'Added a Emergency Contacts',
            description: 'You have successfully added a new Emergency Contacts',
          },
          { root: true },
        )
        return emergencyContacts
      })
      .catch((error) => {
        const errorMessage = error.response.data.message
        console.log(error, 'error in the redux')
        commit(
          'notification/NEW',
          {
            type: 'warning',
            message: 'Create Error',
            description: errorMessage.join('\n'),
          },
          { root: true },
        )
      })
    },
    async updateEmergencyContact({ commit }, payload) {
      const { id, data } = payload
      return updateEmergencyContact(id, data)
        .then((emergencyContact) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Emergency Contact updated',
              description: 'You have successfully updated the Emergency Contact',
            },
            { root: true },
          )
          return emergencyContact
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Update Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    async deleteEmergencyContact({ commit }, payload) {
      return deleteEmergencyContact(payload)
        .then((emergencyContact) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Emergency Contact deleted',
              description: 'You have successfully deleted a Emergency Contact',
            },
            { root: true },
          )
          return emergencyContact
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a Emergency Contact',
            },
            { root: true },
          )
        })
    },
  },
  getters: {},
}
