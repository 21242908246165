<template>
    <a-tooltip placement="left">
      <template slot="title">
        <span>Switch Dark / Light Theme</span>
      </template>
      <a
        href="javascript: void(0);"
        @click="setTheme(settings.theme === 'default' ? 'dark' : 'default')"
        style="bottom: calc(50% + 60px)"
        :class="$style.icon"
      >
        <i v-if="settings.theme === 'default'" class="fe fe-moon" />
        <i v-if="settings.theme !== 'default'" class="fe fe-sun" />
      </a>
    </a-tooltip>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  computed: {
    ...mapState(['settings']),
  },
  data() {
    return {
      defaultColor: '#4b7cf3',
    }
  },
  methods: {
    setTheme(nextTheme) {
      this.$store.commit('SET_THEME', { theme: nextTheme })
      this.$store.commit('CHANGE_SETTING', {
        setting: 'menuColor',
        value: nextTheme === 'dark' ? 'dark' : 'light',
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
