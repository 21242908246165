import Vue from 'vue'
import apiClient from '@/services/axios'

export function getRoutingStatistics(options) {
  return apiClient.get('/analytics/routing-statistics', {
    params: options,
  })
}

export function getStudentStatistics(options) {
  return apiClient.get('/analytics/student-statistics', {
    params: options,
  })
}

export function saveMapboxUsageStatistic(statistic) {
  return apiClient.post('/analytics/mapbox-statistic', statistic)
}
