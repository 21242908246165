<template>
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3346 25.4668V18.2891C16.3346 17.7368 15.8869 17.2891 15.3346 17.2891H10.668C10.1157 17.2891 9.66797 17.7368 9.66797 18.2891V25.4668"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.66797 11.8281V21.4578C3.66797 23.3434 3.66797 24.2862 4.25376 24.872C4.83954 25.4578 5.78235 25.4578 7.66797 25.4578H18.3346C20.2203 25.4578 21.1631 25.4578 21.7488 24.872C22.3346 24.2862 22.3346 23.3434 22.3346 21.4578V11.8281"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M3.29374 2.45456C3.47278 1.72248 3.5623 1.35643 3.83414 1.14306C4.10599 0.929688 4.48282 0.929688 5.23648 0.929688H20.7635C21.5172 0.929688 21.894 0.929688 22.1659 1.14306C22.4377 1.35643 22.5272 1.72248 22.7063 2.45456L24.4573 9.61431C24.6768 10.5117 24.7865 10.9604 24.6138 11.2935C24.5562 11.4047 24.4782 11.5041 24.3839 11.5865C24.1012 11.8334 23.6387 11.8334 22.7137 11.8334V11.8334C21.4346 11.8334 20.7951 11.8334 20.3096 11.531C20.1423 11.4268 19.9914 11.2985 19.8616 11.1502C19.4851 10.7197 19.3823 10.0893 19.1767 8.82844V8.82844C19.118 8.46839 19.0887 8.28837 19.0291 8.27026C19.0101 8.26449 18.9899 8.26449 18.9709 8.27026C18.9113 8.28837 18.882 8.46839 18.8233 8.82844L18.7088 9.53047C18.6126 10.1206 18.5645 10.4156 18.456 10.6561C18.2094 11.2025 17.7306 11.6095 17.1516 11.765C16.8968 11.8334 16.5979 11.8334 16 11.8334V11.8334C15.4021 11.8334 15.1032 11.8334 14.8484 11.765C14.2694 11.6095 13.7906 11.2025 13.544 10.6561C13.4355 10.4156 13.3874 10.1206 13.2912 9.53047L13.1767 8.82844C13.118 8.46839 13.0887 8.28837 13.0291 8.27026C13.0101 8.26449 12.9899 8.26449 12.9709 8.27026C12.9113 8.28837 12.882 8.46839 12.8233 8.82844L12.7088 9.53047C12.6126 10.1206 12.5645 10.4156 12.456 10.6561C12.2094 11.2025 11.7306 11.6095 11.1516 11.765C10.8968 11.8334 10.5979 11.8334 10 11.8334V11.8334C9.40211 11.8334 9.10316 11.8334 8.84836 11.765C8.26936 11.6095 7.79064 11.2025 7.54403 10.6561C7.43551 10.4156 7.3874 10.1206 7.29119 9.53047L7.17673 8.82844C7.11803 8.46839 7.08867 8.28837 7.02908 8.27026C7.01012 8.26449 6.98988 8.26449 6.97092 8.27026C6.91133 8.28837 6.88197 8.46839 6.82327 8.82844V8.82844C6.6177 10.0893 6.51491 10.7197 6.13836 11.1502C6.00862 11.2985 5.85767 11.4268 5.6904 11.531C5.20494 11.8334 4.56539 11.8334 3.28629 11.8334V11.8334C2.36127 11.8334 1.89877 11.8334 1.61614 11.5865C1.52183 11.5041 1.44382 11.4047 1.38619 11.2935C1.21351 10.9604 1.32325 10.5117 1.54271 9.61431L3.29374 2.45456Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: 'BuildingIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
