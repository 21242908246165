<template>
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1.70312"
      width="24"
      height="11.6471"
      rx="2"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M17.7992 21.1149V21.1149C17.7992 21.6617 17.7992 21.9352 17.7461 22.161C17.5724 22.8996 16.9957 23.4763 16.2571 23.65C16.0313 23.7031 15.7578 23.7031 15.211 23.7031H10.7875C10.2406 23.7031 9.96719 23.7031 9.74132 23.65C9.00274 23.4763 8.42605 22.8996 8.25234 22.161C8.19922 21.9352 8.19922 21.6617 8.19922 21.1149V21.1149"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M21.4016 15.9352V15.9352C21.4016 16.482 21.4016 16.7555 21.3484 16.9813C21.1747 17.7199 20.598 18.2966 19.8595 18.4703C19.6336 18.5234 19.3602 18.5234 18.8133 18.5234H7.1898C6.64295 18.5234 6.36953 18.5234 6.14366 18.4703C5.40508 18.2966 4.8284 17.7199 4.65469 16.9813C4.60156 16.7555 4.60156 16.482 4.60156 15.9352V15.9352"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'ContractIcon',
  props: ['color'],
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
