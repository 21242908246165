import Vue from 'vue'
import { placeToCoordinates } from '@/views/tools/routing/Components/RoutingSwitch/Controllers/AxiosHelpers'
import { listAllDistricts } from '../district/district'
import { getAllBuildings } from '../building/building'
import apiClient from '@/services/axios'

export async function createStudent(payload) {
  return apiClient.post('/students', payload)
}

export async function listAllStudents() {
  return apiClient.get('/students')
}

export async function getStudents(filter) {
  return apiClient.post('/students/searchStudents', filter)
}

export async function getStudentsByPaginate(query) {
  return apiClient.get('/students/paginate', {
    params: query,
  })
}

export async function getStudentsByBuilding(payload) {
  const query = {
    'filter.buildings.id': `$in:${payload.buildingIds.join(',')}`,
    'filter.educationType': payload.educationType,
    limit: -1,
  }
  return apiClient.get(`/students/paginate`, {
    params: query,
  })
}

export async function getStudentsByName(payload) {
  const [firstName, lastName] = payload.split(' ')
  let query
  if (!lastName) {
    query = {
      'filter.firstName': `$sw:${firstName}`,
      limit: -1,
    }
  } else {
    query = {
      'filter.firstName': `${firstName}`,
      'filter.lastName': `$sw:${lastName}`,
      limit: -1,
    }
  }
  return apiClient.get(`/students/paginate`, {
    params: query,
  })
}

export async function getStudentById(id) {
  try {
    const response = await apiClient.get(`/students/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'Student data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `student with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching student by ID',
      error,
    }
  }
}

export async function updateStudent(id, studentData) {
  return await apiClient.patch(`/students/${id}`, studentData)
}

export async function resetAmPickupAddress(id) {
  return apiClient.post(`/students/${id}/reset-am-pickup-address`)
}

export async function resetPmDropoffAddress(id) {
  return apiClient.post(`/students/${id}/reset-pm-dropoff-address`)
}

export async function updateAffliatedAddress(id, address) {
  return apiClient.patch(`/students/affliated-addresses/${id}`, address)
}

export async function removeAffliatedAddress(id) {
  return apiClient.delete(`/students/affliated-addresses/${id}`)
}

export async function deleteStudent(id) {
  return apiClient.delete(`/students/${id}`)
}

export async function deleteStudents(ids) {
  return apiClient.delete('/students', { data: { ids } })
}

function convertDateString(numericalDate) {
  if (!numericalDate) return null

  if (typeof numericalDate === 'number') {
    // Convert numerical date to milliseconds since December 30, 1899 (UTC)
    const millisecondsSince1899 = numericalDate * 24 * 60 * 60 * 1000

    // Subtract 70 years and 1 day in milliseconds
    const millisecondsSince1970 =
      millisecondsSince1899 - (70 * 365 + 18) * 24 * 60 * 60 * 1000

    // Create a Date object using the adjusted milliseconds value
    const dateObject = new Date(millisecondsSince1970)

    // Get the components of the date (month, day, year)
    const month = dateObject.getMonth() + 1 // Month is zero-indexed
    const day = dateObject.getDate()
    const year = dateObject.getFullYear()

    // Format the date components into the desired string format "MM/DD/YYYY"
    const dateString = `${year}-${month}-${day}`

    return dateString
  }

  if (typeof numericalDate === 'string' && isValidDateString(numericalDate)) {
    return numericalDate.trim()
  } else {
    return null
  }
}

export async function uploadExistingStudentData(data) {
  const districtResponse = await listAllDistricts()
  const allDistricts = districtResponse.data
  const buildingResponse = await getAllBuildings()
  const allBulidings = buildingResponse.data
  const studentResponse = await listAllStudents()
  const allStudents = studentResponse.data
  for (let i = 0; i < data.length; i++) {
    if (!data[i].firstName) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} firstName is empty`,
      })
      return
    }
    if (!data[i].lastName) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} lastName is empty`,
      })
      return
    }
    if (!data[i].gender) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} gender is empty`,
      })
      return
    }
    if (!data[i].address) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} address is empty`,
      })
      return
    }
    if (!data[i].city) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} city is empty`,
      })
      return
    }
    if (!data[i].state) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} state is empty`,
      })
      return
    }
    if (!data[i].zip) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} zip is empty`,
      })
      return
    }
    if (!data[i].district) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} district is empty`,
      })
      return
    }
    if (!data[i].ncesID) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} ncesID is empty`,
      })
      return
    }
    if (!data[i].building1) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} building1 is empty`,
      })
      return
    }
    if (!data[i].building1Address) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} building1Address is empty`,
      })
      return
    }
    if (!data[i].studentID) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} studentID is empty`,
      })
      return
    }
    if (!data[i].grade) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} grade is empty`,
      })
      return
    }
  }
  try {
    // check all records is proper to upload, if it contains inappropriate row, return
    for (let s = 0; s < data.length; s++) {
      const element = data[s]
      const student = allStudents.filter(
        (student) => student.studentId === element.studentID.toString().trim(),
      )
      if (student.length > 0) {
        Vue.prototype.$notification.warning({
          message: `Row ${s + 1} Student ID is already exist`,
        })
        return
      }
      // get district id
      const district = allDistricts.find((district) => {
        return (
          district.ncesID === element.ncesID.toString().trim() &&
          district.districtName === element.district.trim()
        )
      })
      if (!district) {
        Vue.prototype.$notification.warning({
          message: 'District error: ' + element.district,
          description:
            "This district hasn't uploaded . District should be uploaded first!",
        })
        return
      }
      const building1 = allBulidings.find((building) => {
        return (
          building.buildingName.trim() === element.building1.trim() &&
          building.address.trim() === element.building1Address.trim()
        )
      })

      if (!building1) {
        Vue.prototype.$notification.warning({
          message: 'Building error: ' + element.building1,
          description:
            "This building hasn't uploaded . Building should be uploaded first!",
        })
        return
      }
      let buildings2 = []
      if (element.building2 && element.building2Address) {
        const building2 = allBulidings.find((building) => {
          return (
            building.buildingName.trim() === element.building2.trim() &&
            building.address.trim() === element.building2Address.trim()
          )
        })

        if (!building2) {
          Vue.prototype.$notification.warning({
            message: 'Building error: ' + element.building2,
            description:
              "This building hasn't uploaded . Building should be uploaded first!",
          })
          return
        }
      }

      if (
        !['pk', 'k', 'PK', 'K', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, '12+']
          .map((n) => n.toString())
          .includes(element.grade?.toString())
      ) {
        Vue.prototype.$notification.warning({
          message: `Row ${s + 1} Grade is not correct`,
        })
        return
      }

      if (!['male', 'female', 'other'].includes(element.gender.toLowerCase())) {
        Vue.prototype.$notification.warning({
          message: `Row ${s + 1} Gender is not correct`,
        })
        return
      }
    }

    // actual import
    for (let s = 0; s < data.length; s++) {
      const element = data[s]

      // get district id
      const district = allDistricts.find((district) => {
        return district.ncesID === element.ncesID.toString().trim()
      })

      const building1 = allBulidings.find((building) => {
        return building.buildingName === element.building1.trim()
      })

      const building2 = allBulidings.find((building) => {
        return building.buildingName === element.building2?.trim()
      })

      // calculate lat/long
      const fullAddress =
        element.address.trim() +
        ', ' +
        element.city.trim() +
        ', ' +
        element.state.trim() +
        ', ' +
        (element.zip + '').trim()
      const response = await placeToCoordinates(fullAddress)
      const long = response.data.features[0].geometry.coordinates[0]
      const lat = response.data.features[0].geometry.coordinates[1]

      // requirement
      const requirement = {
        carSeat: element.carSeat?.trim().toLowerCase() === 'yes' ? true : false,
        boosterSeat:
          element.boosterSeat?.trim().toLowerCase() === 'yes' ? true : false,
        harness: element.harness?.trim().toLowerCase() === 'yes' ? true : false,
        houdiniHarness:
          element.houdiniHarness?.trim().toLowerCase() === 'yes' ? true : false,
        starSeat:
          element.starSeat?.trim().toLowerCase() === 'yes' ? true : false,
        airConditioning:
          element.airConditioning?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        assignedMatron1:
          element.assignedMatron1?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        assignedMatron2:
          element.assignedMatron2?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        assignedMatron3:
          element.assignedMatron3?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        nurse: element.nurse?.trim().toLowerCase() === 'yes' ? true : false,
        independantAid:
          element.independantAid?.trim().toLowerCase() === 'yes' ? true : false,
        epiPen: element.epiPen?.trim().toLowerCase() === 'yes' ? true : false,
        buckleGuard:
          element.buckleGuard?.trim().toLowerCase() === 'yes' ? true : false,
        fivePointHarness:
          element.fivePointHarness?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        windowCushion:
          element.windowCushion?.trim().toLowerCase() === 'yes' ? true : false,
        zipperHarness:
          element.zipperHarness?.trim().toLowerCase() === 'yes' ? true : false,
      }

      const toUploadData = {
        firstName: capitalizeFirstLetter(element.firstName.trim()),
        lastName: capitalizeFirstLetter(element.lastName.trim()),
        nickName: element.nickName?.trim(),
        birthDate: convertDateString(element.birthDay),
        gender: element.gender ? element.gender.trim().toLowerCase() : 'other',
        grade: element.grade === '12+' ? '13' : element.grade,
        address: element.address.trim(),
        apartment: element.apartment,
        city: element.city.trim(),
        state: element.state.trim(),
        zip: element.zip + '',
        lat: element.lat ? element.lat : lat,
        long: element.long ? element.long : long,
        districtId: district.id,
        buildingIds: building2 ? [building1.id, building2.id] : [building1.id],
        studentId: element.studentID,
        activeDate: convertDateString(element.activeDate),
        terminateDate: convertDateString(element.terminateDate),
        active: element.active ? true : false,
        educationType: element.education?.trim(),
        householdId: element.householdID?.trim(),
        requirement: requirement,
        phoneNumber: element.phone,
        email: element.email,
        transportation: element.transportation,
        weekdays: element.weekdays,
        notes: element.notes,
      }

      const uploadStudData = await createStudent(toUploadData)
      console.info(uploadStudData, 'uploadStudData')
      // }

      if (s === data.length - 1) {
        return {
          status: 200,
          message: "Student's uploaded successfully",
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
